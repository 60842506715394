import React from "react";
import {Button, Popconfirm, Table, Tooltip} from "antd";
import {ActiveTableColumns} from "./ActiveTableColumns";
import {StopOutlined} from "@ant-design/icons";
import {fetchWithToken} from "../../../../config/helper";
import {boost_Url} from "../../../../config/api";
import {notification} from "../../../../common/Notification";

const Index = (props: { data: any, loading: boolean, total: number, params: any, setParams: any }) => {

    const cancelBoost = (id: string) => {
        fetchWithToken(boost_Url,'delete',{},JSON.stringify({id})).then((resp)=>{
            if (resp.status === 200) {
                props.setParams({
                    ...props.params,
                    page: 1
                })
            }
            resp.json().then((data) => {
                notification({
                    type: resp.status === 200 ? "success" : "warning",
                    title: resp.status === 200 ? "Success" : "Fail",
                    description: data.message
                })
            })
        })
    }
    const columns = ActiveTableColumns.map((column) => {
        return {
            ...column,
            ...(column.key === "actions"
                ? {
                    render: (id: any) => {
                        return (
                            <div className={"flex flex-wrap gap-4 items-center justify-center"}>
                                <Tooltip title={"Cancel Boost"}>
                                    <Popconfirm title={"Cancel Boost"} description={"Are you sure?"} okText={"Yes"}
                                                cancelText={"No"} onConfirm={() => cancelBoost(id)}>
                                        <Button icon={<StopOutlined/>} danger/>
                                    </Popconfirm>
                                </Tooltip>
                            </div>
                        );
                    },
                }
                : {}),
        };
    });
    const handleChange = (pagination: any) => {
        const temp: any = {
            page: pagination.current,
            pageSize: pagination.pageSize
        }
        props.setParams({...props.params, ...temp})
    }
    return (
        <Table
            loading={props.loading}
            scroll={{y: "60vh"}}
            columns={columns}
            dataSource={props.data}
            onChange={handleChange}
            pagination={{
                current: props.params.page,
                pageSize: props.params.pageSize,
                total: props.total,
                showTotal: (total) => <span className={"text-2xl font-600 capitalize"}>Total: {total}</span>,
                showSizeChanger: true
            }}
        />
    );
};

export default Index;
