import React, {useEffect, useState} from "react";
import SectionBody from "../../common/Section/SectionBody";
import {Tabs} from "antd";
import ActiveTable from "./components/ActiveTable";
import PendingTable from "./components/PendingTable";
import CompletedTable from "./components/CompletedTable";
import {fetchWithToken} from "../../config/helper";
import {boost_Url} from "../../config/api";

const Index = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [params, setParams] = useState<any>({
        page: 1,
        pageSize: 10,
        status: 'approved',
    })
    const items = [
        {
            key: "approved",
            label: "Active",
            children: <ActiveTable data={data} loading={loading} total={total} setParams={setParams} params={params}/>
        },
        {
            key: "new",
            label: "Pending",
            children: <PendingTable data={data} loading={loading} total={total} setParams={setParams} params={params}/>
        },
        {
            key: "completed",
            label: "Completed",
            children: <CompletedTable data={data} loading={loading} total={total} setParams={setParams}
                                      params={params}/>
        },
    ]
    const fetchApiData = () => {
        setLoading(true)
        const {page, pageSize, status} = params;
        const url = `${boost_Url}?page=${page}&pageSize=${pageSize}&status=${status}`;
        fetchWithToken(url, "get").then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    setData(data.results)
                    setTotal(data.total)
                })
            }
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchApiData()
    }, [params])

    const onChange = (key: string) => {
        setParams({
            page: 1,
            pageSize: 10,
            status: key
        })
    };
    return (
        <>
            <SectionBody>
                <Tabs
                    style={{width:'100%'}}
                    type={"card"}
                    items={items}
                    tabBarGutter={10}
                    onChange={onChange}
                />
            </SectionBody>
        </>
    );
};

export default Index;
