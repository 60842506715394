import {Button, Popconfirm, Tag, Tooltip} from "antd";
import React from "react";
import KycImagesModal from "./KycImagesModal";
import dayjs from "dayjs";
import {CheckOutlined, StopOutlined} from "@ant-design/icons";

export const kycColumns = (props: any) => {
    return [
        {
            title: "UserName",
            dataIndex: "userId",
            key: "userName",
            ...props.searchProp("name"),
            render: (row: any) => row?.userName,
            width: "10%"
        },
        {
            title: "Email",
            dataIndex: "userId",
            key: "email",
            render: (row: any) => row?.email,
            ...props.searchProp("email"),
            width: "20%"
        },
        {
            title: "Card Last 4",
            dataIndex: "cardDetails",
            key: "last4",
            ...props.searchProp("last4"),
            render: (row: any) => row?.last4,
            width: "10%"
        },
        {
            title: "Card Expiry",
            dataIndex: "cardDetails",
            key: "expiry",
            width: "10%",
            render: (row: any) => {
                if (row && row["exp_month"] !== undefined && row["exp_year"] !== undefined) {
                    const expMonth = row["exp_month"];
                    const expYear = row["exp_year"];
                    return `${expMonth}/${expYear}`;
                } else {
                    return "N/A";
                }
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: [
                {text: "Pending", value: "pending"},
                {text: "Accepted", value: "accepted"},
                {text: "Denied", value: "denied"}
            ],
            filterMultiple: false,
            render: (status: string) => {
                let color = "";
                switch (status) {
                    case "pending":
                        color = "blue";
                        break;
                    case "accepted":
                        color = "green";
                        break;
                    case "denied":
                        color = "red";
                        break;
                    default:
                        color = "default";
                }
                return <Tag color={color} className={"capitalize"}>{status}</Tag>;
            },
            width: "10%"
        },
        {
            title: "Kyc Type",
            dataIndex: "type",
            key: "type",
            render: (kycType: string) => <span
                className={kycType === "id" ? "uppercase" : "capitalize"}>{kycType}</span>,
            width: "10%"
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text: any) => {
                return text ? dayjs(text).format("DD-MMM-YYYY hh:mm A") : '';
            },
            width: "15%"
        },
        {
            title: "Actions",
            key: "action",
            width: "15%",
            render: (_: any, record: any) => {
                return (
                    <div className={"flex gap-4 flex-wrap"}>
                        <KycImagesModal
                            imageData={record}
                        />
                        {record.status !== "accepted" && record.status !== "denied" &&
                            (
                                <>
                                    <Tooltip title={"Accept KYC"}>
                                        <Popconfirm
                                            title={"Accept"}
                                            description={"Are you sure?"}
                                            okText={"Yes"}
                                            cancelText={"No"}
                                            onConfirm={() => {
                                                const {_id} = record;
                                                props.actions.updateKyc(_id, "accepted");
                                            }}
                                        >
                                            <Button type="primary" ghost icon={<CheckOutlined/>}/>
                                        </Popconfirm>
                                    </Tooltip>
                                    <Tooltip title={"Deny KYC"}>
                                        <Popconfirm
                                            title={"Deny"}
                                            description={"Are you sure?"}
                                            okText={"Yes"}
                                            cancelText={"No"}
                                            onConfirm={() => {
                                                const {_id} = record;
                                                props.actions.updateKyc(_id, "denied");
                                            }}
                                        >
                                            <Button icon={<StopOutlined/>} danger/>
                                        </Popconfirm>
                                    </Tooltip>
                                </>
                            )}
                    </div>
                );
            }
        }
    ];
};
