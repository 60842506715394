import React, {useEffect, useState} from "react";
import SectionHeader from "../../common/Section/SectionHeader";
import SectionBody from "../../common/Section/SectionBody";
import KycTable from "./KycTable";
import {kyc_url} from "../../config/api";
import {fetchWithToken} from "../../config/helper";

const Index:React.FC = () => {
    const [kycData, setKycData] = useState<Record<string, any>[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0)
    const [params, setParams] = useState<Record<string, number>>({
        page: 1,
        pageSize: 10
    })

    useEffect(() => {
        setLoading(true);
        let query = [];
        for (const key of Object.keys(params)) {
            if (key === "pageSize") {
                query.push(`results=${params.pageSize}`)
            } else {
                query.push(`${key}=${params[key]}`);
            }
        }
        const url = `${kyc_url}?${query.join("&")}`;
        fetchWithToken(url, "get").then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    setKycData(data.results)
                    setTotal(data.total)
                }).finally(() => {
                    setLoading(false);
                })
            }
        })
    }, [params]);


    return (
        <>
            <SectionHeader heading={"KYC"}/>
            <SectionBody>
                <KycTable
                    kycData={kycData}
                    loading={loading}
                    setLoading={setLoading}
                    setParams={setParams}
                    total={total}
                    params={params}
                    setKycData={setKycData}
                />
            </SectionBody>
        </>
    );

};

export default Index;
