import { store } from "../store";
import { login } from "../store/reducers/userSlice";
import { tokenType } from "../store/initialStates";
import { refresh_Url} from "./api";
import dayjs from "dayjs";


export async function getValidToken(tokens: tokenType) {
  let created_at: string;
  try {
    if (Object.keys(tokens).indexOf("reCreatedAt") !== -1) {
      created_at = tokens.reCreatedAt;
    } else {
      created_at = tokens.createdAt;
    }
  } catch (e) {
    return false;
  }
  const isAccessExpired = dayjs(created_at).add(tokens.expiry, "s") < dayjs().add(2, "m");
  const isRefreshValid = dayjs(tokens.createdAt).add(tokens.refreshExpiry, "s") > dayjs().add(30, "m");

  if (isAccessExpired) {
    if (isRefreshValid) {
      const resp = await fetch(refresh_Url, {
        method: "POST",
        headers:
          {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
        body: JSON.stringify({
          "refresh": tokens.refreshToken
        })
      });
      if (resp.status === 200) {
        const data = await resp.json();
        const tok = JSON.parse(JSON.stringify(tokens));
        tok["accessToken"] = data["accessToken"];
        tok["reCreatedAt"] = dayjs().valueOf();
        return tok;
      }
    } else {
      return null;
    }
  }
  return tokens;
}
export async function checkValidity(tokens: tokenType) {
  let created_at;
  try {
    if (Object.keys(tokens).indexOf("reCreatedAt") !== -1) {
      created_at = tokens["reCreatedAt"];
    } else {
      created_at = tokens["createdAt"];
    }
  } catch (e) {
    return false;
  }
  const isAccessExpired = dayjs(created_at).add(tokens["expiry"], "s") < dayjs().add(2, "m");
  const isRefreshTokenValid = dayjs(created_at).add(tokens["refreshExpiry"], "s") > dayjs().add(1, "h");
  if (isAccessExpired) {
    if (isRefreshTokenValid) {
      const resp = await fetch(refresh_Url, {
        method: "POST",
        headers:
          {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + tokens["refreshToken"]
          },
        body: JSON.stringify({})
      });
      if (resp.status === 200) {
        const data = await resp.json();
        const tok = JSON.parse(JSON.stringify(tokens));
        tok["accessToken"] = data["accessToken"];
        tok["reCreatedAt"] = dayjs().valueOf();
        tok["refreshToken"] = data["refreshToken"];
        return tok;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return tokens;
  }
}
export async function fetchWithToken(url: string, method: string, headers?: any, body?: string | FormData) {
  const state: any = store.getState();
  const token = await getValidToken(state.user.token);
  store.dispatch(login(token));
  let header = {
    "authorization": `Bearer ${token.accessToken}`,
    "content-type": "application/json",
    "accept": "application/json",
  };
  if (headers)
    header = {
      ...header,
      ...headers
    };
  return await fetch(url, {
    method: method,
    headers: header,
    body
  });
}
