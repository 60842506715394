import React from "react";
import OrganiserDrawer from "../../../../common/organiserDrawer";
import { Skeleton } from "antd";

const Index = (props: {
  data: Record<string, any>,
  loading: boolean
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      {
        props.data.memberIds?.length > 0 &&
          <>
            {
              props.loading ? <div className={"tabler_drawer-wrapper"}><Skeleton.Avatar /></div>
                :
                <div className={"tabler_drawer-wrapper flex-wrapper gap-2 cursor-pointer"} onClick={showDrawer}>
                  {
                    props.data.memberIds.slice(0, 5).map((item: any) => {
                      return (
                        <img src={item.profilePic} className={"h-[12%] w-[12%] rounded-[100%]"}
                             alt={""} />
                      );
                    })
                  }
                  {
                    props.data.memberIds?.length > 6 ?
                      <div>
                        <p>+{props.data.memberIds.length - 5}</p>
                      </div>
                      :
                      props.data.memberIds?.length === 6 &&
                        <img src={props.data.memberIds[5].profilePic}
                             className={"h-[12%] w-[12%] rounded-[100%]"}
                             alt={""} />
                  }
                </div>
            }
              <hr />
          </>
      }
      <OrganiserDrawer onClose={onClose} open={open} showDrawer={showDrawer} data={props.data?.memberIds}
                       coHostMember={"members"} host={props.data?.createrId} />
    </>
  );
};

export default Index;
