import React from "react";
import moment from "moment-timezone";

export const TablerColumns = [
    {
        title: "Event Name",
        dataIndex: "eventId",
        key: "eventId",
        render: (row: any) => row["eventName"],
        width: "15%"
    },
    {
        title: "Tabler Type",
        dataIndex: "tablerType",
        key: "tablerType",
        width: "10%",
        filters: [
            {text: "Club", value: "club"},
            {text: "Custom", value: "custom"}
        ],
        filterMultiple: false,
        render: (value: any) => {
            return <span className={"capitalize"}>{value}</span>;
        }
    },
    {
        title: "Start Time",
        dataIndex: "startTime",
        key: "startTime",
        render: (text: any, row: any) => {
            return moment(text).tz(row.timeZone).format("DD-MMM-YYYY hh:mm A");
        },
        width: "10%"
    },
    {
        title: "Creater Email",
        dataIndex: "createrId",
        key: "createrId",
        render: (row: any) => row["email"],
        width: "15%"
    },
    {
        title: "Additional Info",
        dataIndex: "additionalInfo",
        key: "additionalInfo",
        width: "20%"
    },
    {
        title: "Location",
        dataIndex: "placeId",
        key: "address",
        render: (row: any) => row["formatted_address"],
        width: "15%"
    },
    {
        title: "Actions",
        key: "actions",
        width: "15%",

    }

];


