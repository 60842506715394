import React, {useEffect, useRef, useState} from "react";
import SectionHeader from "../../common/Section/SectionHeader";
import SectionBody from "../../common/Section/SectionBody";
import {Tabler_url} from "../../config/api";
import {Button, InputRef, Popconfirm, Table, Tooltip} from "antd";
import {TablerColumns} from "./TablerColumns";
import {fetchWithToken} from "../../config/helper";
import {DeleteOutlined} from "@ant-design/icons";
import TablerDrawer from "./components/TablerDrawer";
import {notification} from "../../common/Notification";
import getColumnSearchProps from "../../common/ColumnSearch";
import BoostTable from "./components/BoostTable";
import dayjs from "dayjs";
import moment from "moment-timezone";


const Index = () => {
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [params, setParams] = useState<any>({
        page: 1,
        pageSize: 10
    });
    const searchInput = useRef<InputRef>(null);

    const fetchData = () => {
        setLoading(true);
        let query = [];
        for (const key of Object.keys(params)) {
            if (key === "pageSize") {
                query.push(`results=${params.pageSize}`);
            } else {
                query.push(`${key}=${params[key]}`);
            }
        }
        const url = `${Tabler_url}?${query.join("&")}`;
        fetchWithToken(url, "get").then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    setData(data.results)
                    setTotal(data.total);
                });
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData()
    }, [params]);

    const deleteTabler = (id: string) => {
        setLoading(true);
        fetchWithToken(Tabler_url, "delete", {}, JSON.stringify({Id: id}))
            .then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((data) => {
                        notification({
                            type: "success",
                            title: "Deleted Successfully",
                            description: data.message
                        });
                        fetchData();
                    });
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const columns = TablerColumns.map((column: any) => {
        return ({
            ...column,
            ...(["eventId", "createrId", "additionalInfo"].includes(column.dataIndex) ? getColumnSearchProps(searchInput, column.dataIndex) : {}),
            ...(column.key === "actions" ? {
                render: (_: any, record: any) => {
                    return (
                        <div className={"flex flex-wrap gap-4 items-center justify-center"}>
                            <TablerDrawer id={record._id} fetchData={fetchData}/>
                            <BoostTable tableStartTime={moment(record.startTime).tz(record.timeZone).toDate()} id={record._id}/>
                            <Popconfirm
                                placement="topLeft"
                                title="Delete this Table"
                                description="Are you sure to delete this table?"
                                onConfirm={() => deleteTabler(record._id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button icon={<DeleteOutlined/>} danger/>
                            </Popconfirm>
                        </div>
                    );
                }
            } : {})
        });
    });
    const handleChange = (pagination: any, filters: any) => {
        const temp: any = {
            page: pagination.current,
            pageSize: pagination.pageSize
        };
        for (const key of Object.keys(filters)) {
            if (filters[key]) {
                temp[key] = filters[key];
            }
        }
        setParams(temp);
    };

    return (
        <>
            <SectionHeader heading={"Tablers"}/>
            <SectionBody>
                <Table
                    loading={loading}
                    pagination={{
                        current: params.page,
                        pageSize: params.pageSize,
                        total: total,
                        showTotal: (total) => <span className={"text-2xl font-600 capitalize"}>Total: {total}</span>,
                        showSizeChanger: true
                    }}
                    dataSource={data}
                    columns={columns}
                    scroll={{y: "65vh"}}
                    onChange={handleChange}
                />
            </SectionBody>

        </>
    );
};
export default Index;
