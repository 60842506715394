import { createSlice } from "@reduxjs/toolkit";
import { userState } from "../initialStates";


const userSlice = createSlice({
  name: "user",
  initialState: userState,
  reducers: {
    firstLogin: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    login: (state, action) => {
      if (state.token !== null) {
        state.token = action.payload;
      }
    },
    logout: (state) => {
      state.token = null;
      state.user = userState.user;
    }
  }
});
export const { firstLogin, login, logout } = userSlice.actions;
export default userSlice.reducer;
