import React from "react";

export const UserColumns: any = [
    {
        title: "Name",
        key: "name",
        dataIndex: "name",
        width: "10%",
    },
    {
        title: "UserName",
        key: "userName",
        dataIndex: "userName",
        width: "10%",
    },
    {
        title: "Email",
        key: "email",
        dataIndex: "email",
        width: "17%",
    },
    {
        title: "Gender",
        key: "gender",
        dataIndex: "gender",
        width: "10%",
        filters: [
            {text: 'Male', value: 'male'},
            {text: 'Female', value: 'female'},
        ],
        filterMultiple: false,
        render: (value: any) => {
            return <span style={{textTransform: "capitalize"}}>{value}</span>
        }
    },
    {
        title: "Bio",
        key: "bio",
        dataIndex: "bio",
        width: "20%",
    },

    {
        title: "Phone",
        key: "phone",
        dataIndex: "phoneNumber",
        width: "10%",
        render: (_: any, record: any) => {
            if (record.ext && record.phoneNumber) {
                return <span>{`${record["ext"]}-${record.phoneNumber}`}</span>
            } else {
                return "N/A";
            }
        }
    },
    {
        title: "Completed Profile",
        key: "hasCompletedProfile",
        dataIndex: "hasCompletedProfile",
        width: "13%",
        filters: [
            {text: 'Completed', value: true},
            {text: 'Incomplete', value: false},
        ],
        filterMultiple: false,
        render: (_: any, record: any) => {
            if (record.hasCompletedProfile) {
                return "Completed";
            } else {
                return "Incomplete Profile";
            }
        }
    },
    {
        title: "Is Disabled",
        key: "isDisabled",
        dataIndex: "isDisabled",
        width: "10%",
        filters: [
            {text: 'True', value: true},
            {text: 'False ', value: false},
        ],
        filterMultiple: false,
        render: (_: any, record: any) => {
            if (record.isDisabled) {
                return "True";
            } else {
                return "False";
            }
        }
    },
    {
        title: "Actions",
        key: "actions",
       width: "15%"
    },
]



