import React from 'react';
import {Button, Input, Modal, Tooltip, Typography} from "antd";
import {NotificationOutlined} from '@ant-design/icons';
import {fetchWithToken} from "../../../config/helper";
import {sendNotifications_Url} from "../../../config/api";
import {notification} from "../../../common/Notification";

const SendNotification = (props: { email: string, id: string }) => {
    const [open, setOpen] = React.useState(false)
    const [title, setTitle] = React.useState('')
    const [body, setBody] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const sendNotification = () => {
        setLoading(true)
        fetchWithToken(sendNotifications_Url, "post", {}, JSON.stringify({
            ids: [props.id],
            option: 'selected',
            title,
            body
        })).then((resp) => {
            if (resp.status === 200) {
                notification({
                    description: `Notification successfully sent to ${props.email}`,
                    type: "success",
                    title: <p className={"capitalize"}>Notification sent</p>
                });
                setOpen(false)
                setBody('')
                setTitle('')
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    })
                })
            }
        }).catch((e) => {
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            })
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <>
            <Tooltip title={"Send Notification"}>
                <Button
                    icon={<NotificationOutlined/>}
                    onClick={() => setOpen(true)}
                    type={"primary"}
                />
            </Tooltip>
            <Modal
                title={"Send Notification"}
                open={open}
                onCancel={() => {
                    setOpen(false)
                    setBody('')
                    setTitle('')
                }}
                onOk={() => sendNotification()}
                okButtonProps={{loading: loading, disabled: !title || !body}}
                cancelButtonProps={{disabled: loading}}
            >
                <div className={'m-10'}>
                    <Typography.Text strong>Notification Title</Typography.Text>
                    <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={'Title'}
                    />
                </div>
                <div className={'m-10'}>
                    <Typography.Text strong>Notification Body</Typography.Text>
                    <Input.TextArea
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        placeholder={'Body'}
                    />
                </div>
            </Modal>
        </>
    );
};

export default SendNotification;