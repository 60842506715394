import React, {useState} from "react";
import {Button, Drawer, Tooltip} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import MaleSvg from "../../../assets/user/Male_gradient.svg";
import FemaleSvg from "../../../assets/user/Female_gradient.svg";
import CheckSvg from "../../../assets/actions/Check.svg";
import WarningSvg from "../../../assets/user/Warning.svg";
import TableReferrals from "./TableReferrals";
import SignUpReferrals from "./SignUpReferrals";

const ReferralDrawer = (props: {
        id: string,
        data: any
    }) => {
        const [open, setOpen] = useState(false);
        
        const showDrawer = () => {
            setOpen(true);
        };

        const onClose = () => {
            setOpen(false);
        };

        return (
            <>
                <Tooltip title="View">
                    <Button icon={<EyeOutlined/>} type={"primary"} ghost onClick={showDrawer}/>
                </Tooltip>

                <Drawer
                    title="Table Counts"
                    placement="right"
                    onClose={onClose}
                    open={open}
                    headerStyle={{textAlign: "center"}}
                    width={'75%'}
                >
                    <div className={'flex justify-between items-start'}>

                        <div className={'text-5xl mb-2 flex-[3]'}>
                            {props.data.userId.name}
                            <p className={"container-lowerWrapper-content capitalize"}>
                                <span>UserName</span>
                                {props.data.userId.userName}
                            </p>
                            <p className={"container-lowerWrapper-content capitalize"}>
                                <span>Email</span>
                                {props.data.userId.email}
                            </p>
                            <p className={"container-lowerWrapper-content capitalize"}>
                                <span>Gender</span>
                                <span className={'flex'}>
                                    <img src={props.data.userId.gender === "male" ? MaleSvg : FemaleSvg}
                                         className={"upper-icons mr-2"}
                                         alt={"male "}/>
                                    {props.data.userId.gender}
                                </span>

                            </p>
                        </div>
                        <div className={'flex-[2]'}>
                            <p className={"container-lowerWrapper-content capitalize"}>
                                <span>Phone Number Verified</span>
                                <img src={props.data.userId.phoneVerified ? CheckSvg : WarningSvg}
                                     className={"upper-icons"}
                                     alt={"phoneVerified"}/>
                            </p>
                            <p className={"container-lowerWrapper-content capitalize"}>
                                <span>Bio</span>
                                {props.data.userId.bio}
                            </p>
                        </div>
                    </div>
                    <div className={'mt-10'}>
                        <TableReferrals id={props.data.userId._id} tables={props.data.tableCount}/>
                    </div>
                    <div className={'mt-10'}>
                        <SignUpReferrals id={props.data.userId._id} signUp={props.data.signUp}/>
                    </div>
                </Drawer>


            </>
        );
    }
;

export default ReferralDrawer;
