export const referralColumns: any = [
    {
        title: "Name",
        key: "name",
        dataIndex: "userId",
        width: "25%",
        render: (data: any) => data?.name
    },
    {
        title: "User Name",
        key: "userName",
        dataIndex: "userId",
        width: "25%",
        render: (data: any) => data?.userName
    },
    {
        title: "Email",
        key: "email",
        dataIndex: "userId",
        width: "25%",
        render: (data: any) => data?.email
    },
    {
        title: "Sign Up",
        key: "signUp",
        dataIndex: "signUp",
        width: "10%",
        sorter: true
    },

    {
        title: "Tables",
        key: "tables",
        dataIndex: "tableCount",
        width: "20%",
        sorter: true
    },
    {
        title: "Actions",
        key: "actions",
        width: "10%"
    }
];



