import React, {useEffect, useRef, useState} from "react";
import {InputRef, Table} from "antd";
import getColumnSearchProps from "../../common/ColumnSearch";
import SectionHeader from "../../common/Section/SectionHeader";
import SectionBody from "../../common/Section/SectionBody";
import {referralColumns} from "./ReferralColumns";
import {fetchWithToken} from "../../config/helper";
import {referral_Url} from "../../config/api";
import './index.scss'
import ReferralDrawer from "./components/ReferralDrawer";

const Index = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = React.useState<Record<string, any>[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [params, setParams] = React.useState<any>({
        pageSize: 10,
        page: 1
    });
    const searchInput = useRef<InputRef>(null);

    const getReferralData = () => {
        setLoading(true);
        let query = [];
        for (const key of Object.keys(params)) {
            if (key === "pageSize") {
                query.push(`results=${params.pageSize}`);
            } else {
                if (params[key])
                    query.push(`${key}=${params[key]}`);
            }
        }
        const url = `${referral_Url}?${query.join("&")}`;
        fetchWithToken(url, "GET").then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    setData(data.results);
                    setTotal(data.total);
                });
            }
        }).finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        getReferralData();
    }, [params]);

    const handleChange = (pagination: any, filters: any, sorter: any) => {
        const temp: any = {
            page: pagination.current,
            pageSize: pagination.pageSize,
            ...filters,
            sortBy: sorter.field,
            sortOrder: sorter.order
        };
        setParams(temp);
    };

    const columns = referralColumns.map((column: any) => ({
        ...column,
        ...(["userName", "email", "name"].includes(column.key) ? getColumnSearchProps(searchInput, column.dataIndex) : {}),
        ...(column.key === "actions" ? {
            render: (_: any, record: any) => <ReferralDrawer id={record._id} data={record}/>
        } : {})
    }));
    return (
        <>
            <SectionHeader heading={"Referral"}/>
            <SectionBody>
                <Table
                    loading={loading}
                    pagination={{
                        current: params.page,
                        pageSize: params.pageSize,
                        total: total,
                        showTotal: (total) => <span className={"text-2xl font-600 capitalize"}>Total: {total}</span>,
                        showSizeChanger: true
                    }}
                    dataSource={data}
                    columns={columns}
                    scroll={{y: "65vh"}}
                    onChange={handleChange}
                />
            </SectionBody>
        </>
    );
};

export default Index;
