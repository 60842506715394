import React from 'react';
import {fetchWithToken} from "../../../config/helper";
import {Tabler_url} from "../../../config/api";
import {notification} from "../../../common/Notification";
import {Button, Input, Skeleton} from "antd";

const EditName = (props: {
    id: string,
    eventName: string,
    loading: boolean,
    type: string,
    fetchData: any,
    fetchApi: any
}) => {
    const [edit, setEdit] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [eventName, setEventName] = React.useState(props.eventName)

    const saveName = () => {
        const reqBody = JSON.stringify({
            Id: props.id,
            eventName
        });
        setLoading(true);
        fetchWithToken(Tabler_url, "put", {}, reqBody).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    notification({
                        type: "success",
                        title: "Updated Successfully",
                        description: data.message
                    });
                    setEdit(false);
                    props.fetchData();
                    props.fetchApi();
                    setLoading(false);
                });
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    });
                });
                setLoading(false);
            }
        }).catch((e) => {
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            });
            setLoading(false);
        });
    }
    return (
        <div className={"tabler_drawer-wrapper"}>
            {
                props.loading ? <Skeleton paragraph={{rows: 1}}/> :
                    edit ?
                        <>
                            <Input
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                            />
                            <div className={'text-sm'}>*This is a custom event, changing the Name will not change the Location</div>
                            <div className={"flex gap-2 mt-4"}>
                                <Button onClick={saveName} type={"primary"} ghost
                                        loading={loading}>Save</Button>
                                <Button onClick={() => setEdit(false)} type={"primary"}
                                        ghost disabled={loading}>Cancel</Button>
                            </div>
                        </>
                        :
                        <div className={'flex justify-between'}>
                            <span className={"text-[1.4rem] font-semibold"}>{props.eventName}</span>
                            {
                                props.type === 'custom' &&
                                <Button onClick={() => {
                                    setEdit(true);
                                    setEventName(props.eventName)
                                }} type={"primary"} ghost>Edit</Button>
                            }
                        </div>
            }

        </div>
    );
};

export default EditName;