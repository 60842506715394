import React, {useState} from 'react';
import {EditOutlined} from "@ant-design/icons";
import {Button, Input} from "antd";
import {fetchWithToken} from "../../../config/helper";
import {user_Url} from "../../../config/api";
import {notification} from "../../../common/Notification";

const EditBio = (props: { bio: string, userName: string, email: string, userFetchData: any, fetchDrawerData: any }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [bioValue, setBioValue] = useState<string>("");

    const saveBioNewValue = () => {
        const body = JSON.stringify({
            Id: props.email,
            bio: bioValue
        });
        setLoading(true);
        fetchWithToken(user_Url, "put", {}, body).then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    notification({
                        type: "success",
                        title: <p className={"capitalize"}>{props.userName} bio updated</p>,
                        description: data.message
                    });
                    setIsEditing(false);
                    props.fetchDrawerData();
                    props.userFetchData();
                    setLoading(false);
                });
            } else {
                res.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    })
                })
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            })
        });
    };

    return (
        <>
            {
                props.bio ?
                    !isEditing ?
                    <div className={"lowerWrapper-bio-container"}>
                        <p>Bio</p>
                        <p className={"bio-text"}>
                            {props.bio}
                            <EditOutlined
                                className={"edit-outlined"}
                                onClick={() => {
                                    setIsEditing(true);
                                    setBioValue(props.bio);
                                }}
                            />
                        </p>
                    </div>
                    : (
                        <div className={"lowerWrapper-bio-container"}>
                            <p>Bio</p>
                            <Input.TextArea
                                className={"mt-2"}
                                rows={3}
                                value={bioValue}
                                onChange={(e) => setBioValue(e.target.value)}
                            />
                            <div className={"textArea-btn-Wrapper"}>
                                <Button type={"primary"} ghost
                                        onClick={saveBioNewValue}
                                        loading={loading}
                                >Save
                                </Button>
                                <Button type={"primary"} ghost
                                        onClick={() => setIsEditing(false)}
                                        disabled={loading}
                                >Cancel
                                </Button>
                            </div>
                        </div>
                    )
                    :
                    <></>
            }
        </>
    );
};

export default EditBio;