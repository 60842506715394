import {boostPrice} from "../../../../config/constants";
import moment from "moment-timezone";

export const CompteleTableColumns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "userId",
    width: "10%",
    render: (data: any) => data?.name
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "userId",
    width: "10%",
    render: (data: any) => data?.email
  },
  {
    title: "Promo Name",
    key: "promoName",
    dataIndex: "promoName",
    width: "10%",
  },
  {
    title: "Event Name",
    key: "eventName",
    dataIndex: "tablerId",
    width: "10%",
    render: (data: any) => {
      return data.eventId[0].eventName
    }
  },
  {
    title: "Location",
    key: "location",
    dataIndex: "placeId",
    width: "10%",
    render: (data: any) => {
      return data.formatted_address
    }
  },
  {
    title: "Event Start Time",
    key: "startTime",
    dataIndex: "tablerId",
    width: "10%",
    render: (data: any) => {
      return moment(data.startTime).tz(data.timeZone).format("DD-MMM-YYYY hh:mm A Z")
    }
  },
  {
    title: "Boost Start Time",
    key: "startedAt",
    dataIndex: "startedAt",
    width: "10%",
    render: (data: any) => {
      return moment(data).format("DD-MMM-YYYY hh:mm A Z")
    }
  },
  {
    title: "Plan Opted",
    key: "planOpted",
    dataIndex: "planSelected",
    width: "10%",
    render: (data: any) => data?.chargedPayment? boostPrice.find((item) => item.price === data?.chargedPayment)?.text:'Free'
  },
  {
    title: 'status',
    key: 'Status',
    dataIndex: 'status',
    width: '10%'
  }
]
