import React from 'react';
import {RiseOutlined} from "@ant-design/icons";
import {Button, DatePicker, Input, Modal, Segmented, Spin, Typography} from "antd";
import dayjs from "dayjs";
import {fetchWithToken} from "../../../config/helper";
import {customBoost_Url, getAvailability_Url} from "../../../config/api";
import {notification} from "../../../common/Notification";

const BoostTable = (props: { tableStartTime: Date, id: string }) => {
    const [open, setOpen] = React.useState(false)
    const [promoName, setPromoName] = React.useState('')
    const [startAt, setStartAt] = React.useState<number>()
    const [endAt, setEndAt] = React.useState<number>()
    const [endType, setEndType] = React.useState<number>(1)
    const [loadAvailability, setLoadAvailability] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [availability, setAvailability] = React.useState(0)
    const getAvailability = () => {
        setLoadAvailability(true)
        fetchWithToken(getAvailability_Url, 'post', {}, JSON.stringify({tablerId: props.id})).then((resp) => {
            if (resp.status === 400) {
                setAvailability(-1)
            } else if (resp.status === 200) {
                resp.json().then((data) => {
                    setAvailability(data.startTime)
                    setStartAt(data.startTime)
                })
            } else {
                setAvailability(-2)
            }
        }).catch(() => {
            setAvailability(-2)
        }).finally(() => {
            setLoadAvailability(false)
        })
    }

    React.useEffect(() => {
        if (open)
            getAvailability()
    }, [open])

    const boostTable = () => {
        setLoading(true)
        fetchWithToken(customBoost_Url, 'post', {}, JSON.stringify({
            promoName,
            endAt,
            startAt,
            tablerId: props.id
        })).then((resp) => {
            notification({
                type: resp.status === 200 ? "success" : 'error',
                title: resp.status === 200 ? "Boost Successfully" : 'Boost Failed',
                description: resp.status === 200 ? "Table Boosted" : ' Something went wrong'
            });
            if (resp.status === 200) {
                setOpen(false)
            }
        }).catch(() => {
            notification({
                type: 'error',
                title: "Boost Successfully",
                description: 'Api call failed'
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            {
                dayjs(props.tableStartTime).isAfter(dayjs().add(1, "hours")) &&
                <Button icon={<RiseOutlined/>} type={'primary'} onClick={() => setOpen(true)}/>
            }
            <Modal
                title={<>Boost Table<p className={'text-xl font-400'}>Boost this table for free with custom
                    duration</p></>}
                open={open}
                width={600}
                okText={'Boost'}
                onCancel={() => {
                    setOpen(false)
                    setPromoName('')
                    setEndType(1)
                    setEndAt(undefined)
                    setStartAt(undefined)
                }}
                onOk={boostTable}
                okButtonProps={{loading: loading, disabled: !promoName || !startAt || !endAt}}
            >
                <div className={'m-10'}>
                    <Typography.Text strong>Promo Name</Typography.Text>
                    <Input
                        value={promoName}
                        onChange={(e) => setPromoName(e.target.value)}
                        placeholder={'Promo Name'}
                    />
                </div>
                {
                    loadAvailability &&
                    <div className={'m-10'}>
                        <Spin/> Checking Availability
                    </div>
                }
                {
                    !loadAvailability && availability > 0 &&
                    <div className={'m-10 gap-4 flex-col flex'}>
                        <div className={'flex items-center'}>
                            <div className={'flex-1'}>
                                <Typography.Text strong>Start Time</Typography.Text>
                            </div>
                            <div className={'flex-1'}>
                                <Segmented
                                    value={endType}
                                    options={[{label: 'End Time', value: 1}, {label: 'Duration', value: 2}]}
                                    onChange={(value) => {
                                        if (typeof value === "string") {
                                            setEndType(parseInt(value));
                                        } else {
                                            setEndType(value);
                                        }
                                        setEndAt(undefined)
                                    }}
                                />
                            </div>
                        </div>

                        <div className={'flex'}>
                            <div className={'flex-1'}>
                                {dayjs(startAt).format('DD-MMM-YYYY hh:mm A')}
                                <p className={'text-sm'}>*In Your Local Timezone</p>
                            </div>
                            <div className={'flex-1'}>
                                {
                                    endType === 1 ?
                                        <>
                                            <DatePicker
                                                disabledDate={(date) =>
                                                    date.isBefore(dayjs(startAt).add(30, 'minutes')) || date.isAfter(props.tableStartTime)
                                                }
                                                showTime
                                                onChange={(e) => {
                                                    setEndAt(e?.valueOf())
                                                }}
                                            />
                                            <p className={'text-sm'}>*In Your Local Timezone</p>
                                        </>
                                        :
                                        <>
                                            <Input
                                                value={dayjs(endAt).diff(dayjs(startAt), 'hours')}
                                                placeholder={'Duration in Hours'}
                                                type={'number'}
                                                onChange={(e) => {
                                                    const end = dayjs(startAt).add(parseInt(e.target.value), 'hours')
                                                    if (!e.target.value) {
                                                        setEndAt(end.valueOf())
                                                    } else {

                                                        if (end.isBefore(props.tableStartTime))
                                                            setEndAt(end.valueOf())
                                                    }

                                                }}
                                            />
                                            <p className={'text-sm'}>Will end
                                                at: {dayjs(endAt).format('DD-MMM-YYYY HH:mm Z')}</p>
                                        </>

                                }

                            </div>
                        </div>

                    </div>
                }
                {
                    !loadAvailability && availability === -1 &&
                    <div className={'m-10'}>
                        <span>No Slots Available</span>
                    </div>
                }
                {
                    !loadAvailability && availability === -2 &&
                    <div className={'m-10'}>
                        <span>Failed to get Availability</span>
                    </div>
                }

            </Modal>
        </>
    );
};

export default BoostTable;