import React, {useState} from 'react';
import {Button, Skeleton} from "antd";
import {fetchWithToken} from "../../../config/helper";
import {Tabler_url} from "../../../config/api";
import {notification} from "../../../common/Notification";

const EditAdditionalInfo = (props: {
    additionalInfo: any,
    loading: boolean,
    id: any,
    fetchData: any,
    fetchApi: any
}) => {
    const [isEditingAdditionalInfo, setIsEditingAdditionalInfo] = useState(false);
    const [editedAdditionalInfo, setEditedAdditionalInfo] = useState("");
    const [loading, setLoading] = useState(false);


    const editAdditionalInfo = () => {
        const reqBody = JSON.stringify({
            Id: props.id,
            additionalInfo: editedAdditionalInfo
        });
        setLoading(true);
        fetchWithToken(Tabler_url, "put", {}, reqBody).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    notification({
                        type: "success",
                        title: "Updated Successfully",
                        description: data.message
                    });
                    setIsEditingAdditionalInfo(false);
                    props.fetchData();
                    props.fetchApi();
                    setLoading(false);
                });
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    });
                });
                setLoading(false);
            }
        }).catch((e) => {
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            });
            setLoading(false);
        });

    };


    return (
        <>
            {
                !isEditingAdditionalInfo ? (
                    <>
                        <div className={"tabler_drawer-wrapper flex justify-between items-center"}>
                            {
                                props.loading ? <Skeleton paragraph={{rows: 1}}/> : <p>{props.additionalInfo}</p>
                            }
                            {
                                props.loading ? <Skeleton.Button active/> : <Button onClick={() => {
                                    setIsEditingAdditionalInfo(true);
                                    setEditedAdditionalInfo(props.additionalInfo);
                                }} type={"primary"} ghost>Edit</Button>
                            }

                        </div>
                        <hr/>
                    </>
                ) : (
                    <>
                        <div className={"tabler_drawer-wrapper flex flex-col"}>
                            <textarea
                                className={"h-40"}
                                value={editedAdditionalInfo}
                                onChange={(e) => setEditedAdditionalInfo(e.target.value)}
                            />
                            <div className={"flex gap-2 mt-4"}>
                                <Button onClick={editAdditionalInfo} type={"primary"} ghost
                                        loading={loading}>Save</Button>
                                <Button onClick={() => setIsEditingAdditionalInfo(false)} type={"primary"}
                                        ghost disabled={loading}>Cancel</Button>
                            </div>
                        </div>
                        <hr/>
                    </>
                )
            }
        </>
    );
};

export default EditAdditionalInfo;