import React, {useRef} from "react";
import {InputRef, Table} from "antd";
import {kycColumns} from "./KycColumns";
import {kyc_url} from "../../config/api";
import {fetchWithToken} from "../../config/helper";
import {notification} from "../../common/Notification";
import getColumnSearchProps from "../../common/ColumnSearch";

const KycTable = (props: {
    kycData: Record<string, any>[],
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setParams: React.Dispatch<React.SetStateAction<Record<string, number>>>,
    total: number,
    params: Record<string, number>,
    setKycData: React.Dispatch<React.SetStateAction<Record<string, any>[]>>
}) => {
    const searchInput = useRef<InputRef>(null);
    const updateKyc = (id: string, status: string) => {
        const reqBody = JSON.stringify({
            kycId: id,
            requestResponse: status
        });
        props.setLoading(true);
        fetchWithToken(kyc_url, "put", {}, reqBody).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    notification({
                        type: status === "accepted" ? "success" : "warning",
                        title: status === "accepted" ? "Accepted Successfully" : "Denied Successfully",
                        description: data.message
                    })
                    props.setParams({
                        ...props.params,
                        page: 1
                    })
                    props.setLoading(false);
                })
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    })
                })
                props.setLoading(false);
            }
        }).catch((e) => {
            props.setLoading(false);
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            })
        })
    };

    const handleTableChange = (pagination: any, filters: any) => {
        const temp: any = {
            page: pagination.current,
            pageSize: pagination.pageSize
        };
        for (const key of Object.keys(filters)) {
            if (filters[key]) {
                temp[key] = filters[key]
            }
        }
        props.setParams(temp);
    }
    return (
        <Table
            loading={props.loading}
            pagination={{
                current: props.params.page,
                pageSize: props.params.pageSize,
                total: props.total,
                showTotal: (total) => <span className={"text-2xl font-600 capitalize"}>Total: {total}</span>,
                showSizeChanger: true
            }}
            dataSource={props.kycData}
            columns={kycColumns({
                actions: {
                    updateKyc: updateKyc,
                },
                searchProp: (dataIndex:any)=>getColumnSearchProps(searchInput, dataIndex)
            })}
            onChange={handleTableChange}
            scroll={{y: "65vh"}}
        />
    );
};

export default KycTable;
