import React from "react";
import SectionHeader from "../../common/Section/SectionHeader";
import SectionBody from "../../common/Section/SectionBody";
import UserReportsTable from "./UserReports/UserReportsTable";
import {Tabs} from "antd";
import TableReportsTable from "./TableReports/TableReportsTable";

const Index: React.FC = () => {

    const items = [
        {
            key: "user",
            label: "User Reports",
            children: <UserReportsTable/>
        },
        {
            key: "table",
            label: "Table Reports",
            children: <TableReportsTable/>
        },
    ]

    return (
        <>
            <SectionHeader heading={"Reports"}/>
            <SectionBody>
                <Tabs
                    style={{width:'100%'}}
                    type={"card"}
                    items={items}
                    tabBarGutter={10}
                />
            </SectionBody>
        </>
    );

};

export default Index;
