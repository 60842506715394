import React from 'react';
import {Button, DatePicker, Skeleton} from "antd";
import moment from "moment-timezone";
import CalendarSvg from "../../../assets/Tablers/calendar_drawer.svg";
import {fetchWithToken} from "../../../config/helper";
import {Tabler_url} from "../../../config/api";
import {notification} from "../../../common/Notification";
import dayjs from "dayjs";

const EditStartTime = (props: {
    id: any,
    loading: boolean,
    startTime: any,
    timeZone: any,
    fetchData: any,
    fetchApi: any
}) => {
    const [edit, setEdit] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [date, setDate] = React.useState(props.startTime)

    const saveStartTime = () => {
        const reqBody = JSON.stringify({
            Id: props.id,
            startTime: date
        });
        setLoading(true);
        fetchWithToken(Tabler_url, "put", {}, reqBody).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    notification({
                        type: "success",
                        title: "Updated Successfully",
                        description: data.message
                    });
                    setEdit(false);
                    props.fetchData();
                    props.fetchApi();
                    setLoading(false);
                });
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    });
                });
                setLoading(false);
            }
        }).catch((e) => {
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            });
            setLoading(false);
        });
    }
    return (
        <div className={"tabler_drawer-wrapper flex-wrapper gap-5"}>
            {
                props.loading ? <Skeleton.Avatar shape={"circle"}/> : <img src={CalendarSvg} alt={""}/>
            }
            {
                props.loading ? <Skeleton paragraph={{rows: 1}}/> :

                    edit ?
                        <div className={"tabler_drawer-wrapper flex flex-col"}>
                            <DatePicker value={dayjs(moment(date).tz(props.timeZone).toDate())} showTime onOk={(date)=>setDate(date.valueOf())} />
                            <div className={"flex gap-2 mt-4"}>
                                <Button onClick={saveStartTime} type={"primary"} ghost
                                        loading={loading}>Save</Button>
                                <Button onClick={() => setEdit(false)} type={"primary"}
                                        ghost disabled={loading}>Cancel</Button>
                            </div>
                        </div>
                        :
                        <>
                            <p>{moment(props.startTime).tz(props.timeZone).format("DD-MMM-YYYY hh:mm A")}</p>

                            <Button onClick={() => {
                                setEdit(true);
                                setDate(moment(props.startTime).tz(props.timeZone).toDate().getTime());
                            }} type={"primary"} ghost>Edit</Button>
                        </>
            }

        </div>
    );
};

export default EditStartTime;