import {createSlice} from "@reduxjs/toolkit"
import {tablerState} from "../initialStates"

const tablerSlice = createSlice({
  name:"tabler",
  initialState:tablerState,
  reducers:{
    setTablerData:(state,action) =>{
      state.data = action.payload
    }
  }
})

export const {setTablerData} = tablerSlice.actions;
export default tablerSlice.reducer;
