export const userState = {
  token: null,
  user: {}
};

const tokenData = {
  accessToken: "",
  expiry: 0,
  refreshToken: "",
  refreshExpiry: 0,
  createdAt: "",      // for refresh token and access token
  reCreatedAt: ""    // only for newly generated access token
};

export type tokenType = typeof tokenData;

export const tablerState :{data:any[]} = {
  data:[]

}
