import dayjs from "dayjs";
import moment from "moment-timezone";

export const referralDrawerTableColumns = [
    {
        title: "Event Name",
        key: "eventId",
        dataIndex: "tableId",
        width: "12%",
        render: (data: any) => data.eventId.eventName
    },
    {
        title: "Creator Email",
        key: "createrId",
        dataIndex: "tableId",
        width: "12%",
        render: (data: any) => data.createrId.email
    },
    {
        title: "Location",
        key: "placeId",
        dataIndex: "tableId",
        width: "15%",
        render: (data: any) => data.placeId.name
    },
    {
        title: "Start Time",
        key: "startTime",
        dataIndex: "tableId",
        width: "15%",
        render: (data: any) => moment(data.startTime).tz(data.timeZone).format("DD-MMM-YYYY hh:mm A Z")
    },
    {
        title: "Counts",
        key: "count",
        dataIndex: "count",
        width: "10%"
    }

];
