import React from "react";
import "./index.scss";
import {
    CategoryScale,
    Chart as ChartJs,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip, BarController, LineController
} from "chart.js";
import EventChart from "./components/EventChart";
import BoostMyTableChart from "./components/BoostMyTableChart";
import UserAcquisitionChat from "./components/UserAcquisitionChat";
import {fetchWithToken} from "../../config/helper";
import {getAllStats_url} from "../../config/api";
import Stats from "./components/Stats";

ChartJs.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, Tooltip, BarController, LineController);
const Index = () => {
    const [data, setData] = React.useState<any>({
        stats: {},
        tables: [],
        boosts: [],
        users: []
    })

    React.useEffect(() => {
        fetchWithToken(getAllStats_url, 'get').then((resp) => {
            if (resp.status === 200) {
                resp.json().then((stats) => {
                    setData(stats)
                })
            }
        })
    }, [])

    return (
        <div className={"dashboard-container"}>
            <div className={'p-5 w-full flex gap-10'}>
                <Stats data={data.stats}/>
            </div>
            <div className={'p-5 w-1/2'}>
                <UserAcquisitionChat chartData={data.users}/>
            </div>
            <div className={'p-5 w-1/2'}>
                <EventChart chartData={data.tables}/>
            </div>
            <div className={'p-5 w-1/2'}>
                <BoostMyTableChart chartData={data.boosts}/>
            </div>
        </div>
    );
};

export default Index;
