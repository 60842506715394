import dayjs from "dayjs";

export const referralDrawerSignupColumns = [
  {
    title: "Date",
    key: "date",
    dataIndex: "date",
    width: "50%",
    render: (data: any) => dayjs(data).format("DD-MMM-YYYY hh:mm A")
  },
  {
    title: "Count",
    key: "count",
    dataIndex: "signUp",
    width: "50%"
  }

];
