import React from 'react';
import {fetchWithToken} from "../../../config/helper";
import {Tabler_url} from "../../../config/api";
import {notification} from "../../../common/Notification";
import {Button, Input, Skeleton} from "antd";
import PerformerSvg from "../../../assets/Tablers/Vinyl.svg";

const EditPerformer = (props: { id: string, performer: string, loading: boolean, fetchData: any, fetchApi: any }) => {
    const [edit, setEdit] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [performer, setPerformer] = React.useState(props.performer)

    const savePerformer = () => {
        const reqBody = JSON.stringify({
            Id: props.id,
            performer
        });
        setLoading(true);
        fetchWithToken(Tabler_url, "put", {}, reqBody).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    notification({
                        type: "success",
                        title: "Updated Successfully",
                        description: data.message
                    });
                    setEdit(false);
                    props.fetchData();
                    props.fetchApi();
                    setLoading(false);
                });
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    });
                });
                setLoading(false);
            }
        }).catch((e) => {
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            });
            setLoading(false);
        });
    }
    return (
        <div className={"tabler_drawer-wrapper flex-wrapper gap-5"}>
            {
                props.loading ? <Skeleton.Avatar shape={"circle"}/> : <img src={PerformerSvg} alt={""}/>
            }

            {
                props.loading ? <Skeleton paragraph={{rows: 1}}/> :

                    edit ?
                        <div className={"tabler_drawer-wrapper flex flex-col"}>
                            <Input
                                value={performer}
                                onChange={(e) => setPerformer(e.target.value)}
                            />
                            <div className={"flex gap-2 mt-4"}>
                                <Button onClick={savePerformer} type={"primary"} ghost
                                        loading={loading}>Save</Button>
                                <Button onClick={() => setEdit(false)} type={"primary"}
                                        ghost disabled={loading}>Cancel</Button>
                            </div>
                        </div>
                        :
                        <>
                            <p>{props.performer}</p>

                            <Button onClick={() => {
                                setEdit(true);
                                setPerformer(props.performer)
                            }} type={"primary"} ghost>Edit</Button>
                        </>
            }

        </div>
    );
};

export default EditPerformer;