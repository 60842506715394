import React, {useState} from 'react';
import {EditOutlined} from "@ant-design/icons";
import {Button, Input} from "antd";
import {fetchWithToken} from "../../../config/helper";
import {user_Url} from "../../../config/api";
import {notification} from "../../../common/Notification";

const EditName = (props: { name: string, email:string, userName:string,fetchDrawerData:any,userFetchData:any }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [name, setName] = useState<string>("");

    const saveNameNewValue = () => {
        const body = JSON.stringify({
            Id: props.email,
            name
        });
        setLoading(true);
        fetchWithToken(user_Url, "put", {}, body).then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    notification({
                        type: "success",
                        title: <p className={"capitalize"}>Name updated for {props.userName}</p>,
                        description: data.message
                    });
                    setIsEditing(false);
                    props.fetchDrawerData();
                    props.userFetchData();
                    setLoading(false);
                });
            } else {
                res.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    })
                })
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            })
        });
    };

    return isEditing ?
        <div className={'px-5'}>
            <Input
                className={"mt-2"}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <div className={"flex justify-end gap-5 mt-2"}>
                <Button type={"primary"} ghost
                        onClick={saveNameNewValue}
                        loading={loading}
                >Save
                </Button>
                <Button type={"primary"} ghost
                        onClick={() => setIsEditing(false)}
                        disabled={loading}
                >Cancel
                </Button>
            </div>
        </div>
        :
        <h1 className={"container-upperWrapper-data-heading"}>
            {props.name}
            <EditOutlined
                className={"edit-outlined"}
                onClick={() => {
                    setIsEditing(true);
                    setName(props.name);
                }}
            />
        </h1>
};

export default EditName;