export const boostPrice = [
    {
        text: "Full-Day $30 Boost (12-24 hours)",
        price: 30,
        min: 12,
        max: 24
    },
    {
        text: "Extended $24 Boost (5-12 hours)",
        price: 24,
        min: 5,
        max: 12
    },
    {
        text: "Quick $15 Boost (5 hours or less)",
        price: 15,
        min: 1,
        max: 5
    }
]