import moment from "moment-timezone";
import {boostPrice} from "../../../../config/constants";

export const PendingTableColumns = [
    {
        title: "Name",
        key: "name",
        dataIndex: "userId",
        width: "10%",
        render: (data: any) => data?.name
    },
    {
        title: "Email",
        key: "email",
        dataIndex: "userId",
        width: "10%",
        render: (data: any) => data?.email
    },
    {
        title: "Promo Name",
        key: "promoName",
        dataIndex: "promoName",
        width: "10%",
    },
    {
        title: "Event Name",
        key: "eventName",
        dataIndex: "tablerId",
        width: "10%",
        render: (data: any) => {
            return data.eventId[0].eventName
        }
    },
    {
        title: "Location",
        key: "location",
        dataIndex: "placeId",
        width: "10%",
        render: (data: any) => {
            return data.formatted_address
        }
    },
    {
        title: "Event Start Time",
        key: "startTime",
        dataIndex: "tablerId",
        width: "10%",
        render: (data: any) => {
            return moment(data.startTime).tz(data.timeZone).format("DD-MMM-YYYY hh:mm A Z")
        }
    },
    {
        title: "Plan Opted",
        key: "planOpted",
        dataIndex: "planSelected",
        width: "10%",
        render: (data: any) => boostPrice.find((item) => item.price === data?.chargedPayment)?.text
    },
    {
        title: "Requested At",
        key: "createdAt",
        dataIndex: "createdAt",
        width: "10%",
        render: (data: any) => {
            return moment(data).format("DD-MMM-YYYY hh:mm A Z")
        }
    },
    {
        title: "Actions",
        key: "actions",
        width: "10%",
    }
]
