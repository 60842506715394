import React, { useState } from 'react';
import { Button, Modal, Tabs, TabsProps, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const KycImagesModal = (props:{imageData:any}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }
  let items: TabsProps['items'];

  if (props.imageData.type === "id") {
    items = [
      {
        key: '1',
        label: 'Front Image',
        children: <img src={props.imageData.frontImage} alt={"front image"} className="max-w-full h-auto" />,
      },
      {
        key: '2',
        label: 'Back Image',
        children: <img src={props.imageData.backImage} alt={"Back Image"} className="max-w-full h-auto" />
      },
      {
        key: '3',
        label: 'Selfie Image',
        children: <img src={props.imageData.selfieImage} alt={"selfie"} className="max-w-full h-auto" />
      },
    ];
  } else {
    items = [
      {
        key: '1',
        label: 'Passport Image',
        children: <img src={props.imageData.passportImage} alt={"front image"} className="max-w-full h-auto" />,
      },
      {
        key: '2',
        label: 'Selfie Image',
        children: <img src={props.imageData.selfieImage} alt={"Back Image"} className="max-w-full h-auto" />
      },
    ];
  }
  const onChange = (key: string) => {
    console.log(key);
  };
  return (
    <>
      <Tooltip title="View KYC Image">
        <Button icon={<EyeOutlined/>} type={"primary"} ghost onClick={showModal}/>
      </Tooltip>
      <Modal title="Kyc Images" open={isModalOpen}  onCancel={handleCancel} footer={""} className={"w-[600px]"}>
        <Tabs style={{width:'100%'}} defaultActiveKey="1" items={items} onChange={onChange} />
      </Modal>
    </>
  );
};

export default KycImagesModal;
