export const BASE_URL = "https://tabler-v2-admin-prod-atlrbm5ukq-ue.a.run.app";
// export const BASE_URL = "http://localhost:8080";

//authentication

export const login_url = BASE_URL + "/api/v1/auth/login";
export const refresh_Url = BASE_URL + "/api/v1/auth/refresh";

//dashboard
export const getAllStats_url = BASE_URL + "/api/v1/stats/all";
export const getEventStats_url = BASE_URL + "/api/v1/stats/events";
export const getBoostStats_url = BASE_URL + "/api/v1/stats/boost";
export const getUserStats_url = BASE_URL + "/api/v1/stats/users";

//reports
export const userReports_url = BASE_URL + "/api/v1/reports/user";
export const tableReports_url = BASE_URL + "/api/v1/reports/table";
export const closeReports_url = BASE_URL + "/api/v1/reports/close";

//Kyc
export const kyc_url = BASE_URL + "/api/v1/kyc";

// Tabler
export const Tabler_url = BASE_URL + "/api/v1/tabler";
export const getPlaces_url = BASE_URL + "/api/v1/tabler/getPlaces?type=";

// User
export const user_Url = BASE_URL + "/api/v1/user";
export const userNameCheck_Url = BASE_URL + "/api/v1/user/checkUsername";
export const sendNotifications_Url = BASE_URL + "/api/v1/user/sendNotifications";

// BoostMyTable

export const boost_Url = BASE_URL + "/api/v1/boostTable";
export const getAvailability_Url = BASE_URL + "/api/v1/boostTable/getAvailability";
export const customBoost_Url = BASE_URL + "/api/v1/boostTable/customBoost";

// ReferralTable
export const referral_Url = BASE_URL + "/api/v1/referral";
export const referralTable_Url = BASE_URL + "/api/v1/referral/table";
export const referralSignup_Url = BASE_URL + "/api/v1/referral/signup";
