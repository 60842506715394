import React, {useState} from "react";
import {Button, Drawer, Tooltip, Skeleton} from "antd";
import {Tabler_url} from "../../../config/api";
import LocationSvg from "../../../assets/Tablers/TargetLocation.svg";
import CloseSvg from "../../../assets/Tablers/Close_Gradient.svg";
import FemaleGradient from "../../../assets/Tablers/femaleIcon_gradient.svg";
import MaleGradient from "../../../assets/Tablers/male_gradient1.svg";
import "./index.scss";
import {fetchWithToken} from "../../../config/helper";
import {EyeOutlined} from "@ant-design/icons";
import CohostDrawer from "./CohostDrawer";
import Member from "./Member";
import EditAdditionalInfo from "./EditAdditionalInfo";
import EditStartTime from "./EditStartTime";
import EditPerformer from "./EditPerformer";
import EditDrink from "./EditDrink";
import EditName from "./EditName";
import EditLocation from "./EditLocation";

const TablerDrawer = (props: { id: string, fetchData: () => void | undefined }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(false);
    const fetchApi = () => {
        setLoading(true);
        fetchWithToken(`${Tabler_url}/${props.id}`, "get").then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    setData(data);
                    setOpen(true);
                });
            }
        }).finally(() => {
            setLoading(false);
        });
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="View">
                <Button icon={<EyeOutlined/>} type={"primary"} ghost onClick={fetchApi} loading={loading}/>
            </Tooltip>
            {
                data &&
                <Drawer
                    title={"Event Details"}
                    width={500}
                    placement="right"
                    onClose={onClose}
                    open={open}
                    className={"tabler_drawer_container"}
                    headerStyle={{textAlign: "center"}}
                >
                    <div className={"tabler_drawer-wrapper"}>
                        {
                            loading ? <div className={"py-2 flex justify-center"}>
                                    <Skeleton.Image active/>
                                </div>
                                : <img src={data.eventId?.imageUrl} className={"event-img-dimensions"} alt={""}/>
                        }
                    </div>
                    <EditName
                        id={props.id}
                        loading={loading}
                        eventName={data.eventId?.eventName}
                        type={data.eventId?.eventType}
                        fetchData={props.fetchData}
                        fetchApi={fetchApi}
                    />
                    <hr/>
                    <CohostDrawer data={data} loading={loading}/>
                    <hr/>
                    <p className={"text-[1.2rem] px-8 pt-5 font-semibold"}>Additional Info</p>
                    <EditAdditionalInfo
                        additionalInfo={data.additionalInfo} fetchApi={fetchApi}
                        fetchData={props.fetchData} id={props.id} loading={loading}
                    />
                    {
                        data.drink &&
                        <>
                            <EditDrink
                                id={props.id} drink={data.drink} loading={loading}
                                fetchData={props.fetchData} fetchApi={fetchApi}
                            />
                            <hr/>
                        </>
                    }
                    {
                        data.startTime &&
                        <>
                            <EditStartTime
                                loading={loading} startTime={data.startTime} timeZone={data.timeZone}
                                fetchApi={fetchApi} fetchData={props.fetchData} id={props.id}
                            />
                            <hr/>
                        </>
                    }
                    {
                        data.performer &&
                        <>
                            <EditPerformer
                                id={props.id} loading={loading} performer={data.performer}
                                fetchApi={fetchApi} fetchData={props.fetchData}
                            />
                            <hr/>
                        </>
                    }
                    {
                        data.placeId?.formatted_address &&
                        <>
                            <EditLocation
                                loading={loading} placeId={data.placeId} type={data.eventId?.eventType}
                                fetchApi={fetchApi} fetchData={props.fetchData} id={props.id}
                            />
                            <hr/>
                        </>
                    }
                    <Member data={data} loading={loading}/>
                    <div className={"tabler_drawer-wrapper flex-wrapper gap-5"}>
                        {
                            loading ? <Skeleton.Avatar shape={"circle"}/> : <img src={CloseSvg} alt={""}/>
                        }
                        {
                            loading ? <Skeleton paragraph={{rows: 1}}/> :
                                <p>Cancellation Policy: {data.cancellationPolicy}</p>
                        }
                    </div>
                    <hr/>
                    <div className={"tabler_drawer-wrapper"}>
                        <div className={"flex-wrapper justify-between"}>
                            <div>
                                <div className={"flex-wrapper gap-5"}>
                                    {
                                        loading ? <Skeleton.Avatar shape={"circle"}/> :
                                            <img src={FemaleGradient} alt={""}/>
                                    }
                                    <p>Female</p>
                                </div>
                                <div className={"flex items-center gap-1"}>
                                    {
                                        loading ? <Skeleton paragraph={{rows: 1}}/> :
                                            <p className={"text-lg"}>{data.currency}</p>
                                    }
                                    {
                                        loading ? <Skeleton paragraph={{rows: 1}}/> :
                                            <p className={"text-lg"}>{data.coTablers?.female?.amount}</p>
                                    }
                                </div>
                            </div>
                            <div className={"coTabler-counter-wrapper"}>
                                {
                                    loading ? <Skeleton.Avatar shape={"square"}/> :
                                        <p>{data.coTablers?.female?.count}</p>
                                }
                            </div>
                        </div>
                        <div className={"flex-wrapper justify-between mt-4"}>
                            <div>
                                <div className={"flex-wrapper gap-5"}>
                                    {
                                        loading ? <Skeleton.Avatar shape={"circle"}/> :
                                            <img src={MaleGradient} alt={""}/>
                                    }
                                    <p>Male</p>
                                </div>
                                <div className={"flex items-center gap-1"}>
                                    {
                                        loading ? <Skeleton paragraph={{rows: 1}}/> :
                                            <p className={"text-lg"}>{data.currency}</p>
                                    }
                                    {
                                        loading ? <Skeleton paragraph={{rows: 1}}/> :
                                            <p className={"text-lg"}>{data.coTablers?.male?.amount}</p>
                                    }
                                </div>
                            </div>
                            <div className={"coTabler-counter-wrapper"}>
                                {
                                    loading ? <Skeleton.Avatar shape={"square"}/> : <p>{data.coTablers?.male?.count}</p>
                                }
                            </div>
                        </div>
                    </div>
                </Drawer>
            }

        </>
    );
};

export default TablerDrawer;
