import React from "react";
import {Typography} from "antd";
import "./index.scss"
const SectionHeader = (props:any) => {
  return (
    <div className={"main-section-header shadow-card"}>
      <>
        {
          !props.heading
            ? props.headerNode
            : <Typography.Title
              level={4}
              className={"main-section-header-heading"}>
              {props.heading}
            </Typography.Title>
        }
        {props.children}
      </>
    </div>
  );
};

export default SectionHeader;
