import React, {useState} from 'react';
import {Table} from "antd";
import {referralDrawerTableColumns} from "./ReferralDrawerTableColumns";
import {fetchWithToken} from "../../../config/helper";
import {referralTable_Url} from "../../../config/api";

const TableReferrals = (props: { id: string, tables: number }) => {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = React.useState<number>(0);
    const [params, setParams] = React.useState<any>({
        pageSize: 10,
        page: 1
    });
    const handleChange = (pagination: any) => {
        const temp: any = {
            page: pagination.current,
            pageSize: pagination.pageSize
        };
        setParams(temp);
    };

    const fetchData = () => {
        setLoading(true);
        fetchWithToken(`${referralTable_Url}?page=${params.page}&results=${params.pageSize}&id=${props.id}`, "GET").then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    setData(data.results);
                    setTotal(data.total);
                });
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    React.useEffect(() => {
        fetchData()
    }, [params])

    return (
        <>
            <div className={'mb-2'}>
                <span className={'text-3xl mr-2'}>Table Referrals</span>
                <span>(Total: {props.tables})</span>

            </div>
            <Table
                loading={loading}
                dataSource={data}
                columns={referralDrawerTableColumns}
                onChange={handleChange}
                pagination={{
                    current: params.page,
                    pageSize: params.pageSize,
                    total: total,
                    showTotal: (total) => <span
                        className={"text-2xl font-600 capitalize"}>Total: {total}</span>,
                    showSizeChanger: true
                }}
                scroll={{x: 1000, y: "40vh"}}
            />
        </>
    );
};

export default TableReferrals;