import React from "react";
import {Button, Popconfirm, Table, Tooltip} from "antd";
import {CompteleTableColumns} from "./CompleteTableColumns";
import {CheckOutlined, StopOutlined} from "@ant-design/icons";

const Index = (props: { data: any, loading: boolean, total: number, params: any, setParams: any }) => {

    const columns = CompteleTableColumns.map((column) => {
        return {
            ...column,
            ...(column.key === "actions"
                ? {
                    render: (_: any, record: any) => {
                        return (
                            <div className={"flex flex-wrap gap-4 items-center justify-center"}>
                                <Tooltip title={"Accept KYC"}>
                                    <Popconfirm title={"Approve"} description={"Are you sure?"} okText={"Yes"}
                                                cancelText={"No"}>
                                        <Button type="primary" ghost icon={<CheckOutlined/>}/>
                                    </Popconfirm>
                                </Tooltip>
                                <Tooltip title={"Deny KYC"}>
                                    <Popconfirm title={"Deny"} description={"Are you sure?"} okText={"Yes"}
                                                cancelText={"No"}>
                                        <Button icon={<StopOutlined/>} danger/>
                                    </Popconfirm>
                                </Tooltip>
                            </div>
                        );
                    },
                }
                : {}),
        };
    });
    const handleChange = (pagination: any) => {
        const temp: any = {
            page: pagination.current,
            pageSize: pagination.pageSize
        }
        props.setParams({...props.params,...temp})
    }
    return (
        <Table
            loading={props.loading}
            scroll={{y: "60vh"}}
            columns={columns}
            dataSource={props.data}
            onChange={handleChange}
            pagination={{
                current: props.params.page,
                pageSize: props.params.pageSize,
                total: props.total,
                showTotal: (total) => <span className={"text-2xl font-600 capitalize"}>Total: {total}</span>,
                showSizeChanger: true
            }}
        />
    );
};

export default Index;
