import React from "react";
import {Chart} from "react-chartjs-2";
import {DatePicker, Segmented} from "antd";
import dayjs from "dayjs";
import {fetchWithToken} from "../../../config/helper";
import {getUserStats_url} from "../../../config/api";

const {RangePicker} = DatePicker;
const UserAcquisitionChat = (props: {
    chartData: any,
}) => {
    const [period, setPeriod] = React.useState('1')
    const [data, setData] = React.useState(props.chartData)
    const [unit, setUnit] = React.useState('day')
    const [start, setStart] = React.useState<any>(null)
    const [end, setEnd] = React.useState<any>(null)

    const fetchData = () => {
        fetchWithToken(getUserStats_url + `?type=${period}&start=${start?.valueOf()}&end=${end?.valueOf()}`, 'get').then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    setData(data.users)
                    setUnit(data.unit)
                })
            }
        })
    }

    React.useEffect(() => {
        if (period === '1') {
            setData(props.chartData)
            setUnit('day')
        } else {
            fetchData()
        }
    }, [period, props.chartData, start, end])

    const options: any = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "bottom",
                labels: {
                    color: "#000"
                }
            }
        },
        scales: {
            x: {
                grid: {display: false}
            },
            y: {
                // grid: { display: false },
                suggestedMin: 0,
            }
        }
    };

    const chartData = {
        labels: data.map((data: any) => dayjs(data._id).format(unit === 'day' ? 'DD-MMM-YY' : 'MMM-YY')),
        datasets: [
            {
                label: 'Users',
                data: data.map((data: any) => data.count),
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#ecf0f1",
                    "#f0331a",
                    "#f3ba2f",
                    "#2a71d0"
                ],
                borderColor: "black",
                borderWidth: 0.5,
                barThickness: 20
            }
        ]
    }

    return (
        <div className={"chart-wrapper"}>
            <div className={"flex items-center justify-between w-full"}>
                <div className={'flex-1'}>
                    <div className={"capitalize font-bold text-2xl mr-1"}>User Acquisition</div>
                    <div className={"text-xl ml-1"}>(Total:
                        {data.reduce((acc: number, data: any) => acc + data.count, 0)})
                    </div>
                </div>
                <div className={' flex flex-col justify-center gap-2 items-center'}>
                    <Segmented
                        value={period}
                        options={[{label: "7 Days", value: '1'}, {label: "This Month", value: '2'}]}
                        onChange={(value) => {
                            setPeriod(value.toString())
                            setStart(null)
                            setEnd(null)
                        }}
                    />
                    <RangePicker
                        value={[start, end]}
                        onChange={(value) => {
                            if (value) {
                                setStart(value[0])
                                setEnd(value[1])
                                setPeriod('3')
                            }
                        }}
                    />
                </div>
            </div>
            <Chart
                type={"bar"}
                data={chartData}
                options={options}
            />
        </div>
    );
};

export default UserAcquisitionChat;
