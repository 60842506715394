import React from "react";
import {Button, Input, Popconfirm, Table, Tooltip} from "antd";
import {PendingTableColumns} from "./PendingTableColumns";
import {CheckOutlined, StopOutlined} from "@ant-design/icons";
import {fetchWithToken} from "../../../../config/helper";
import {boost_Url} from "../../../../config/api";
import {notification} from "../../../../common/Notification";

const Index = (props: { data: any, loading: boolean, total: number, params: any, setParams: any }) => {
    const [loading, setLoading] = React.useState(false)
    const [reason, setReason] = React.useState("")
    const update = (requestResponse: string, id: string, isFree:boolean=false) => {
        setLoading(true)
        fetchWithToken(boost_Url, "post", {}, JSON.stringify({
            id, requestResponse, reason, isFree
        })).then((resp) => {
            if (resp.status === 200) {
                props.setParams({
                    page: 1,
                    pageSize: 10,
                    reset: props.params.reset,
                    status: props.params.status
                })
            }
            resp.json().then((data) => {
                notification({
                    type: resp.status === 200 ? "success" : "warning",
                    title: resp.status === 200 ? "Success" : "Fail",
                    description: data.message
                })
            })
        }).finally(() => {
            setLoading(false)
            setReason("")
        })
    }

    const columns = PendingTableColumns.map((column) => {
        return {
            ...column,
            ...(column.key === "actions"
                ? {
                    render: (_: any, record: any) => {
                        return (
                            <div className={"flex flex-wrap gap-4 items-center justify-center"}>
                                <Tooltip title={"Accept Boost"}>
                                    <Popconfirm title={"Approve"} description={"Are you sure?"} okText={"Yes"}
                                                cancelText={"No"} onConfirm={() => update("approve", record._id)}>
                                        <Button type="primary" ghost icon={<CheckOutlined/>} loading={loading}/>
                                    </Popconfirm>
                                </Tooltip>
                                <Tooltip title={"Deny Boost"}>
                                    <Popconfirm title={"Deny"}
                                                description={<>Are you sure?
                                                    <Input
                                                        value={reason}
                                                        placeholder={"Reason"}
                                                        onChange={(e) => setReason(e.target.value)}
                                                    />
                                                </>}
                                                onCancel={() => setReason("")}
                                                okText={"Yes"}
                                                cancelText={"No"} onConfirm={() => update("deny", record._id)}>
                                        <Button icon={<StopOutlined/>} danger loading={loading}/>
                                    </Popconfirm>
                                </Tooltip>
                                <Tooltip title={"Accept Boost For Free"}>
                                    <Popconfirm title={"Approve For Free"} description={"Are you sure?"} okText={"Yes"}
                                                cancelText={"No"} onConfirm={() => update("approve", record._id, true)}>
                                        <Button type="primary" ghost icon={<CheckOutlined/>} loading={loading}>
                                            Free
                                        </Button>
                                    </Popconfirm>
                                </Tooltip>
                            </div>
                        );
                    },
                }
                : {}),
        };
    });
    const handleChange = (pagination: any) => {
        const temp: any = {
            page: pagination.current,
            pageSize: pagination.pageSize
        }
        props.setParams({...props.params,...temp})
    }
    return (
        <Table
            loading={props.loading}
            scroll={{y: "60vh"}}
            columns={columns}
            dataSource={props.data}
            onChange={handleChange}
            pagination={{
                current: props.params.page,
                pageSize: props.params.pageSize,
                total: props.total,
                showTotal: (total) => <span className={"text-2xl font-600 capitalize"}>Total: {total}</span>,
                showSizeChanger: true
            }}
        />
    );
};

export default Index;
