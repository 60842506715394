import {Button, Input, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";


const getColumnSearchProps = (searchInput: any, dataIndex: any):any => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}:any) => (
        <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
            <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                // @ts-ignore
                value={selectedKeys}
                onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : '')}
                style={{marginBottom: 8, display: 'block'}}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => confirm()}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{width: 90}}
                >
                    Search
                </Button>
                <Button
                    onClick={() => {
                        if (clearFilters)
                            clearFilters()
                        confirm()
                    }}
                    size="small"
                    style={{width: 90}}
                >
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    close
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
    ),
    onFilterDropdownOpenChange: (visible:any) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
    },
});
export default getColumnSearchProps