import React, { useState } from "react";
import { Button, Form, Input } from "antd";
// @ts-ignore
import "./index.scss";
// @ts-ignore
import SplashScreen from "../../assets/splash_screen.png";
import { login_url } from "../../config/api";
import { connect } from "react-redux";
import { firstLogin } from "../../store/reducers/userSlice";

const Login = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const onFinish = (value: any) => {
    setLoading(true);
    fetch(login_url, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({ email: value.email, password: value.password })
    }).then((resp) => {
      if (resp.status === 200) {
        resp.json().then((data) => {
          props.firstLogin({
            token: data.token,
            user: data.user
          });
        });
      } else {
        resp.json().then((data) => {
          setError(data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
      }
    }).finally(() => {
      setLoading(false);
    });
  };
  return (
    <section className={"login-container"}>
      <div className={"login-container-wrapper"}>
        <div className={"login-image-container"}>
          <img src={SplashScreen} alt={"logo"} style={{ objectFit: "cover", width: "100%" }} />
        </div>
        <div className={"form-container"}>
          <div className={"form-title"}>
            Tabler Inc.
          </div>
          <Form
            layout={"vertical"}
            className={"form"}
            requiredMark={false}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              label={"Email"}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!"
                },
                {
                  required: true,
                  message: "Enter your E-mail!"
                }
              ]}
            >
              <Input placeholder={"Email"} />
            </Form.Item>
            <Form.Item
              name="password"
              label={"Password"}
              rules={[
                {
                  required: true,
                  message: "Enter password!"
                }
              ]}
            >
              <Input.Password placeholder={"Password"} />
            </Form.Item>
            <div className={"flex my-5"}>
              {/*<Link to={"/forgot-password"} className={"fgt-pwd"}>Forgot Credentials ?</Link>*/}
            </div>
            <p className={"text-red-400"}>{error}</p>
            <Button htmlType={"submit"} className={"submit-btn"} loading={loading}>Login</Button>
          </Form>
        </div>
      </div>
    </section>
  );
};
export default connect(null, { firstLogin })(Login);
