import React from 'react';
import {AutoComplete, Button, Skeleton} from "antd";
import LocationSvg from "../../../assets/Tablers/TargetLocation.svg";
import {useDebounce} from "usehooks-ts";
import {fetchWithToken} from "../../../config/helper";
import {getPlaces_url, Tabler_url} from "../../../config/api";
import {notification} from "../../../common/Notification";

const Item = (props: { label: string, subLabel: string }) => {
    return <div className={'flex items-center gap-2'}>
        <span>{props.label}</span>
        <span className={'text-sm'}>{props.subLabel}</span>
    </div>
}

const EditLocation = (props: {
    loading: boolean,
    placeId: any,
    type: string,
    id: string,
    fetchData: any,
    fetchApi: any
}) => {
    const [edit, setEdit] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [loadingSuggestions, setLoadingSuggestions] = React.useState(false)
    const [search, setSearch] = React.useState(props.placeId.name)
    const [selected, setSelected] = React.useState(props.placeId.place_id)
    const [suggestions, setSuggestions] = React.useState<{ label: any, value: string }[]>([])
    const debouncedValue = useDebounce(search, 1000)

    React.useEffect(() => {
        const found = suggestions.find((item: any) => item.label.props.label === debouncedValue);
        if (debouncedValue !== "" && !found) {
            setLoadingSuggestions(true)
            fetchWithToken(`${getPlaces_url}${props.type === "custom" ? "" : "establishment"}&query=${debouncedValue}`, "get").then((res) => {
                res.json().then((res) => {
                    const updatedSuggestions = res.data.map((item: Record<string, any>) => ({
                        label: <Item
                            label={item["structured_formatting"]["main_text"]}
                            subLabel={item["structured_formatting"]["secondary_text"]}
                        />,
                        value: item["place_id"],
                    }));
                    setSuggestions(updatedSuggestions);
                });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoadingSuggestions(false)
            });
        }
    }, [debouncedValue])

    const onSearch = (value: string) => {
        setSearch(value)
        setSelected('')
    }

    const saveLocation = () => {
        const reqBody = JSON.stringify({
            Id: props.id,
            placeId: selected
        });
        setLoading(true)
        fetchWithToken(Tabler_url, "put", {}, reqBody).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    notification({
                        type: "success",
                        title: "Updated Successfully",
                        description: data.message
                    });
                    setEdit(false);
                    props.fetchData();
                    props.fetchApi();
                    setLoading(false);
                });
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    });
                });
                setLoading(false);
            }
        }).catch((e) => {
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            });
            setLoading(false);
        });
    }
    return (
        <>
            <div className={"tabler_drawer-wrapper flex-wrapper gap-5"}>
                {
                    props.loading ? <Skeleton.Avatar shape={"circle"}/> : <img src={LocationSvg} alt={""}/>
                }
                {
                    props.loading ? <Skeleton paragraph={{rows: 1}}/> :
                        edit ?
                            <div className={'flex-1'}>
                                <AutoComplete
                                    options={
                                        search === props.placeId.place_id ? [{
                                            label: <Item label={props.placeId.name}
                                                         subLabel={props.placeId.formatted_address}/>,
                                            value: props.placeId.place_id
                                        }] : suggestions
                                    }
                                    onSearch={onSearch}
                                    onSelect={(value, option) => {
                                        setSelected(value)
                                        setSearch(option.label.props.label)
                                    }}
                                    value={search}
                                    placeholder={props.type === 'custom' ? 'Location' : 'Venue Name'}
                                    style={{width: '100%'}}
                                    notFoundContent={loadingSuggestions ? 'Loading...' : 'No Content Found'}
                                >
                                </AutoComplete>
                                <div className={"flex gap-2 mt-4"}>
                                    <Button
                                        onClick={saveLocation}
                                        type={"primary"}
                                        loading={loading}
                                        disabled={!selected || selected === props.placeId.place_id}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        onClick={() => setEdit(false)}
                                        type={"primary"}
                                        ghost
                                        disabled={loading}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>

                            :
                            <>
                                <p>{props.placeId.formatted_address}</p>
                                {
                                    props.type === 'custom' && <Button onClick={() => {
                                        setEdit(true);
                                        setSearch(props.placeId.name)
                                        setSelected(props.placeId.place_id)
                                    }} type={"primary"} ghost>Edit</Button>
                                }
                            </>
                }

            </div>
        </>
    );
};

export default EditLocation;