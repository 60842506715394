import React, {useState} from "react";
import "../index.scss";
import OrganiserDrawer from "../../../../common/organiserDrawer";
import {Skeleton} from "antd";

const CohostDrawer = (props: { data: Record<string, any>, loading: boolean }) => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        if (props.data.coHostIds?.length)
            setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className={"flex-wrapper gap-5 tabler_drawer-wrapper"}>
                {
                    props.loading ? <Skeleton.Avatar/>
                        :
                        <div className={`coHost-image-wrapper ${props.data.coHostIds?.length ? 'cursor-pointer' : ''}`}
                             onClick={showDrawer}>
                            <img src={props.data.createrId?.profilePic} className={"coHost-image-dimensions"} alt={""}/>
                            {
                                props.data.coHostIds?.length === 1 ?
                                    props.data.coHostIds.map((item: any) => {
                                        return (
                                            <img src={item.profilePic} className={"coHost-smallImage-dimensions"}
                                                 alt={""}/>
                                        );
                                    })
                                    :
                                    props.data.coHostIds?.length > 1 ?
                                        <div
                                            className={"coHost-smallImage-dimensions bg-gradient-to-r from-[#6AA5FF] to-[#E02BF0] grid place-items-center"}>
                                            <p className={"text-[#fff]"}>{props.data.coHostIds?.length}</p>
                                        </div>
                                        : undefined
                            }
                        </div>
                }
                {
                    props.loading ? <Skeleton paragraph={{rows: 1}}/> :
                        <p className={"text-[1.4rem] font-medium"}>{props.data.createrId?.name}</p>
                }

            </div>
            <OrganiserDrawer open={open} onClose={onClose} data={props.data?.coHostIds} host={props.data?.createrId}
                             showDrawer={showDrawer} coHostMember={"coHost"}/>
        </>
    );
};


export default CohostDrawer;

