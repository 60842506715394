import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {userState, tablerState} from "./initialStates";
import rootReducer from "./reducers";
import {configureStore} from "@reduxjs/toolkit";

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["user"]
};

const createStore = (preloadedState: object) => {
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware: any) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: ["persist/PERSIST"],
                },
            }),
        preloadedState: preloadedState
    });
};

export const store = createStore({
    user: userState,
    tabler: tablerState
});
export const persistor = persistStore(store);

export type RootStateType = ReturnType<typeof store.getState>


