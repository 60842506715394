import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes as Switch } from "react-router-dom";
import Login from "./Authentication";
import Kyc from "./Kyc";
import Navbar from "../common/navbar";
import { checkValidity } from "../config/helper";
import { connect } from "react-redux";
import { login, logout } from "../store/reducers/userSlice";
import Tablers from "./Tablers";
import Users from "./Users";
import BoostMyTable from "./BoostMyTable";
import Referral from "./Referral";
import Dashboard from "./Dashboard";
import Reports from "./Reports";

const AuthFlow = (
  <>
    <Switch>
      <Route path={"*"} element={<Navigate to={"/login"} />} />
      <Route path={"/login"} element={<Login />} />

    </Switch>
  </>
);
const AppFlow = (
  <>
    <Navbar />
    <main className={"main-section"}>
      <Switch>
        <Route path={"*"} element={<Navigate to={"/dashboard"} />} />
        <Route path={"/dashboard"} element={<Dashboard />} />
        <Route path={"kyc"} element={<Kyc />} />
        <Route path={"tabler"} element={<Tablers />} />
        <Route path={"user"} element={<Users />} />
        <Route path={"boost"} element={<BoostMyTable />} />
        <Route path={"referral"} element={<Referral />} />
        <Route path={"reports"} element={<Reports />} />
      </Switch>
    </main>
  </>
);
const Routes = (props: any) => {
  const [validating, setValidating] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (props.token !== null) {
      checkValidity(props.token).then((status) => {
        if (status === false) {
          props.logout();
        } else {
          props.login(status);
        }
      }).finally(() => {
        setValidating(false);
      });
    } else {
      setValidating(false);
    }
  }, []);

  useEffect(() => {
    if (props.token && !loggedIn) {
      setLoggedIn(true);
    } else if (!props.token && loggedIn) {
      setLoggedIn(false);
    }
  }, [props.token, loggedIn]);

  if (validating) {
    return <></>;
  }
  return (
    <BrowserRouter>
      {
        loggedIn ? AppFlow : AuthFlow
      }
    </BrowserRouter>
  );
};
const mapStateToProps = (state: any) => {
  return {
    token: state.user.token
  };
};
export default connect(mapStateToProps, { login, logout })(Routes);
