import React from "react";
import "./index.scss"

const SectionBody = (props:any) => {
  const baseClass = "main-section-body shadow-card";
  return (
    <div className={props.className ? [baseClass, props.className].join(" ") : baseClass}>
      {props.children}
    </div>
  );
};

export default SectionBody;
