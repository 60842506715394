import React, {useState} from 'react';
import {Avatar, Button, Drawer, Skeleton, Tooltip} from 'antd';
import {user_Url} from "../../../config/api";
import {EyeOutlined} from '@ant-design/icons';
import UserSvg from "../../../assets/user/Navbar_User_gradient.svg";
import InstagramSvg from "../../../assets/user/Instagram_gradient.svg";
import DrinkSvg from "../../../assets/user/Whiskey_cheers_Gradient.svg";
import TikTok from "../../../assets/user/Tiktok_gradient.svg";
import SnapChat from "../../../assets/user/Snapchat_gradient.svg";
import CheckSvg from "../../../assets/actions/Check.svg";
import WarningSvg from "../../../assets/user/Warning.svg";
import MaleSvg from "../../../assets/user/Male_gradient.svg";
import FemaleSvg from "../../../assets/user/Female_gradient.svg";
import CloseSvg from "../../../assets/actions/Close_Gradient.svg";
import MailSvg from "../../../assets/user/Email.svg";
import PhoneSvg from "../../../assets/user/phone.svg";
import MaleUserSvg from "../../../assets/user/male_gradient_icon.svg";
import FemaleUserSvg from "../../../assets/user/femaleIcon_gradient.svg";
import {fetchWithToken} from "../../../config/helper";
import DisableUser from "./DisableUser";
import EditBio from "./EditBio";
import EditName from "./EditName";
import EditUsername from "./EditUsername";
import EditDrink from "./EditDrink";
import SendNotification from "./SendUserNotification";


const UserDrawer = (props: {
    id: string,
    isDisabled: boolean,
    email: string,
    userName: string,
    userFetchData: () => void | undefined
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [data, setData] = useState<Record<string, any>>({});
    const [loading, setLoading] = useState<boolean>(false);


    const fetchDrawerData = () => {
        setLoading(true);
        fetchWithToken(`${user_Url}/${props.id}`, "get").then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    setData(data);
                })
            }
        }).finally(() => {
            setLoading(false);
        })
    }
    const showDrawer = () => {
        setOpen(true);
        fetchDrawerData();
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className={"user-action-wrapper"}>
                <Tooltip title={"Full Details View"}>
                    <Button icon={<EyeOutlined/>} type={"primary"} ghost onClick={showDrawer}/>
                </Tooltip>
                <DisableUser
                    email={props.email} isDisabled={props.isDisabled} userFetchData={props.userFetchData}
                    userName={props.userName}
                />
                <SendNotification email={props.email} id={props.id}/>
            </div>
            {
                data &&
                <Drawer title={"User Details"} headerStyle={{textAlign: "center"}} placement="right" onClose={onClose}
                        open={open}>
                    <div className={"container-upperWrapper"}>
                        {
                            loading ?
                                <div className={"container-skeleton-loading"}>
                                    <Skeleton.Image active/>
                                </div>
                                : <div className={"container-upperWrapper-avatar"}>
                                    <Avatar
                                        src={data.profilePic}
                                        alt={"avatar"}
                                        size={80}
                                        style={{backgroundColor: "#2B3555"}}
                                        icon={<img src={data.gender === "male" ? MaleUserSvg : FemaleUserSvg}
                                                   alt={"icon"}/>}
                                    />
                                </div>
                        }
                        {
                            loading ?
                                <Skeleton active/>
                                :
                                <section className={"container-upperWrapper-data"}>
                                    {
                                        data.name &&
                                        <EditName
                                            name={data.name} userName={data.userName} email={data.email}
                                            fetchDrawerData={fetchDrawerData} userFetchData={props.userFetchData}
                                        />
                                    }
                                    {
                                        data.phoneNumber && data.ext && <p>
                                            <img src={PhoneSvg} className={"upper-icons"} alt={"phone"}/>
                                            {`${data.ext}-${data.phoneNumber}`}
                                        </p>
                                    }
                                    {
                                        data.userName && <p>
                                            <img src={UserSvg} className={"upper-icons"} alt={"user"}/>
                                            <EditUsername
                                                userName={data.userName} email={data.email}
                                                fetchDrawerData={fetchDrawerData} userFetchData={props.userFetchData}
                                            />
                                        </p>
                                    }
                                    {
                                        MailSvg && <p>
                                            <img src={MailSvg} className={"upper-icons"} alt={"mail"}/>
                                            {data.email}
                                        </p>
                                    }
                                </section>
                        }
                    </div>
                    <footer className={"container-lowerWrapper"}>
                        <h3>Other Details</h3>
                        {
                            loading ?
                                <div className={"py-4"}>
                                    <Skeleton active/>
                                </div>
                                : (
                                    <>
                                        <p className={"container-lowerWrapper-content capitalize"}>
                                            <img src={data.gender === "male" ? MaleSvg : FemaleSvg}
                                                 className={"upper-icons"}
                                                 alt={"male "}/>
                                            {data.gender}
                                        </p>
                                        {
                                            data.instagram && <p className={"container-lowerWrapper-content"}>
                                                <img src={InstagramSvg} className={"upper-icons"} alt={"Instagram"}/>
                                                {data.instagram}
                                            </p>
                                        }
                                        {
                                            data.snapChat && <p className={"container-lowerWrapper-content"}>
                                                <img src={TikTok} className={"upper-icons"} alt={"tiktok"}/>
                                                {data.snapChat}
                                            </p>
                                        }
                                        {
                                            data.ticktok && <p className={"container-lowerWrapper-content"}>
                                                <img src={SnapChat} className={"upper-icons"} alt={"snapchat"}/>
                                                {data.ticktok}
                                            </p>
                                        }
                                        {
                                            data.drink && <p className={"container-lowerWrapper-content capitalize"}>
                                                <img src={DrinkSvg} className={"upper-icons"} alt={"drink"}/>
                                                <EditDrink
                                                    drink={data.drink} email={data.email}
                                                    fetchDrawerData={fetchDrawerData} userFetchData={props.userFetchData}
                                                />
                                            </p>
                                        }
                                        <p className={"container-lowerWrapper-content capitalize"}>
                                            <span>Cancellation Policy</span>
                                            {data.cancellationPolicy}
                                        </p>

                                        <p className={"container-lowerWrapper-content"}>
                                            <span>Completed Profile</span>
                                            <img src={data.hasCompletedProfile ? CheckSvg : WarningSvg}
                                                 className={"upper-icons"}
                                                 alt={"completeProfile"}/>
                                        </p>
                                        <p className={"container-lowerWrapper-content"}>
                                            <span>Phone Number</span>
                                            <img src={data.phoneVerified ? CheckSvg : WarningSvg} className={"upper-icons"}
                                                 alt={"phoneVerified"}/>
                                        </p>
                                        <p className={"container-lowerWrapper-content"}>
                                            <span>Email Verified</span>
                                            <img src={data.emailVerified ? CheckSvg : WarningSvg} className={"upper-icons"}
                                                 alt={"Email Verified"}/>
                                        </p>
                                        <p className={"container-lowerWrapper-content"}>
                                            <span>Ghost Mode</span>
                                            <img src={data.ghostMode ? CheckSvg : CloseSvg} className={"upper-icons"}
                                                 alt={"ghostMode"}/>
                                        </p>
                                        {
                                            data.currency && <p className={"container-lowerWrapper-content"}>
                                                <span>Currency</span>
                                                <span className={"uppercase"}>{data.currency}</span>
                                            </p>
                                        }
                                        {
                                            data.stripeCustomerId && <p className={"container-lowerWrapper-content"}>
                                                <span>Stripe Customer Id</span>
                                                {data.stripeCustomerId}
                                            </p>
                                        }
                                        {
                                            data.stripeAccountId && <p className={"container-lowerWrapper-content"}>
                                                <span>Stripe Account Id</span>
                                                {data.stripeAccountId}
                                            </p>
                                        }
                                        <EditBio
                                            bio={data.bio} userName={data.userName} email={data.email}
                                            fetchDrawerData={fetchDrawerData} userFetchData={props.userFetchData}
                                        />
                                        {
                                            data.searchRadius && <p className={"container-lowerWrapper-content"}>
                                                <span>Search Radius</span>
                                                {`${data.searchRadius.toFixed(1)} ${data.searchRadiusUnit}`}
                                            </p>
                                        }
                                        {
                                            data.company &&
                                            <p className={"container-lowerWrapper-content"}>
                                                <span>Company</span>
                                                {data.company}
                                            </p>
                                        }
                                        {
                                            data.jobTitle && <p className={"container-lowerWrapper-content"}>
                                                <span>Job Title</span>
                                                {data.jobTitle}
                                            </p>
                                        }
                                    </>
                                )
                        }
                    </footer>
                </Drawer>
            }
        </>
    );
};
export default UserDrawer;
