import React from 'react';
import {Button, Tooltip} from "antd";
import {StopOutlined} from "@ant-design/icons";
import {fetchWithToken} from "../../../config/helper";
import {user_Url} from "../../../config/api";
import {notification} from "../../../common/Notification";

const DisableUser = (props: { email: string, userName: string, isDisabled: boolean, userFetchData: any }) => {
    const [loading, setLoading] = React.useState(false)
    const enableDisableBtn = (disabled: boolean) => {
        setLoading(true);
        const disabledUserBody = JSON.stringify({
            Id: props.email,
            isDisable: disabled
        });
        fetchWithToken(user_Url, "put", {}, disabledUserBody).then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    notification({
                        type: "success",
                        title: disabled ? <p className={"capitalize"}>{props.userName} disabled successfully</p> :
                            <p className={"capitalize"}>{props.userName} enabled successfully</p>,
                        description: data.message
                    });
                    props.userFetchData();
                })
                setLoading(false);
            } else {
                res.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    })
                })
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            })
        })
    }

    return (
        <Tooltip title={props.isDisabled ? "User Enabled" : "User Disabled"}>
            <Button icon={<StopOutlined/>} onClick={() => enableDisableBtn(!props.isDisabled)}
                    danger={!props.isDisabled} loading={loading} type={"primary"}/>
        </Tooltip>
    );
};

export default DisableUser;