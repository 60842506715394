import {Button, Popconfirm, Tag, Tooltip} from "antd";
import React from "react";
import dayjs from "dayjs";
import {CheckOutlined} from "@ant-design/icons";

export const userReportsColumns = (props: any) => {
    return [
        {
            title: "Reported user",
            dataIndex: "reportedUser",
            key: "reportedUser",
            // ...props.searchProp("reportedUser"),
            render: (row: any) => row?.email,
            width: "20%"
        },
        {
            title: "Reported By",
            dataIndex: "reportedBy",
            key: "reportedBy",
            render: (row: any) => row?.email,
            // ...props.searchProp("reportedBy"),
            width: "20%"
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            width: "25%"
        },
        {
            title: "Reported On",
            dataIndex: "reportedOn",
            key: "reportedOn",
            width: "15%",
            render: (data: any) => dayjs(data).format('DD-MMM-YYYY hh:mm A Z')
        },
        {
            title: "Status",
            dataIndex: "actedOn",
            key: "actedOn",
            filters: [
                {text: "Open", value: false},
                {text: "Closed", value: true}
            ],
            filterMultiple: false,
            render: (actedOn: string) => {
                if (actedOn)
                    return <Tag color={'success'} className={"capitalize"}>Closed</Tag>;
                else
                    return <Tag color={'volcano'} className={"capitalize"}>Open</Tag>;

            },
            width: "10%"
        },
        {
            title: "Actions",
            dataIndex: "actedOn",
            key: "action",
            width: "10%",
            render: (actedOn: boolean, record: any) => {
                return (
                    <div className={"flex gap-4 flex-wrap"}>
                        {
                            actedOn || <Tooltip title={"Close the case"}>
                                <Popconfirm
                                    title={"Accept"}
                                    description={"Are you sure?"}
                                    okText={"Yes"}
                                    cancelText={"No"}
                                    onConfirm={() => {
                                        const {_id} = record;
                                        props.actions.closeReport(_id);
                                    }}
                                >
                                    <Button type="primary" ghost icon={<CheckOutlined/>}/>
                                </Popconfirm>
                            </Tooltip>
                        }
                    </div>
                );
            }
        }
    ];
};
