import {notification as NotificationApi} from "antd";
import React from "react";
type notifyPropsType = {
    type: "success" | "info" | "warning" | "error";
    title: string|React.ReactElement;
    description: string;
    duration?: number;
}
export const notification = (props: notifyPropsType) => {
    const {type, title, description, duration} = props;
    NotificationApi[type]({
        message: title,
        description: description,
        duration: duration ? duration : 3,
        style: {
            marginTop: "5vh",
        }
    });
};