import React, {useState} from 'react';
import {EditOutlined, CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {Button, Input, Spin} from "antd";
import {fetchWithToken} from "../../../config/helper";
import {user_Url, userNameCheck_Url} from "../../../config/api";
import {notification} from "../../../common/Notification";
import {useDebounce} from "usehooks-ts";

const EditUsername = (props: { userName: string, email: string, fetchDrawerData: any, userFetchData: any }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>(props.userName);
    const [valid, setValid] = React.useState(false)
    const [validating, setValidating] = React.useState(false)
    const debUserName = useDebounce(userName, 1500)
    const checkUserName = () => {
        fetchWithToken(userNameCheck_Url, "post", {}, JSON.stringify({userName: debUserName})).then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    setValid(!data.isTaken)
                })
            } else {
                setValid(false)
            }
        }).catch(() => {
            setValid(false)
        }).finally(() => {
            setValidating(false)
        })
    }

    const saveUserNameNewValue = () => {
        const body = JSON.stringify({
            Id: props.email,
            userName
        });
        setLoading(true);
        fetchWithToken(user_Url, "put", {}, body).then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    notification({
                        type: "success",
                        title: <p className={"capitalize"}>UserName updated for {props.email}</p>,
                        description: data.message
                    });
                    setIsEditing(false);
                    props.fetchDrawerData();
                    props.userFetchData();
                    setLoading(false);
                });
            } else {
                res.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    })
                })
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            })
        });
    };

    React.useEffect(() => {
        console.log(debUserName)
        if (debUserName === props.userName) {
            setValid(true)
        } else {
            checkUserName()
        }
    }, [debUserName, props.userName])

    return (
        <span>
            {
                !isEditing ?
                    <>
                        {props.userName}
                        <EditOutlined
                            className={"edit-outlined"}
                            onClick={() => {
                                setIsEditing(true);
                                setUserName(props.userName);
                            }}
                        />
                    </>
                    :
                    <>
                        <Input
                            className={"mt-2"}
                            value={userName}
                            onChange={(e) => {
                                setValid(false)
                                setValidating(true)
                                setUserName(e.target.value)
                            }}
                            suffix={userName !== props.userName ?
                                validating ?
                                    <Spin size={'small'}/>
                                    :
                                    valid ?
                                        <CheckCircleOutlined style={{color: 'green'}}/>
                                        :
                                        <CloseCircleOutlined style={{color: 'red'}}/>
                                :
                                <></>
                            }
                        />
                        <div className={"flex justify-end gap-5 mt-2"}>
                            <Button type={"primary"} ghost
                                    onClick={saveUserNameNewValue}
                                    loading={loading}
                                    disabled={validating || !valid || props.userName===userName}
                            >Save
                            </Button>
                            <Button type={"primary"} ghost
                                    onClick={() => setIsEditing(false)}
                                    disabled={loading}
                            >Cancel
                            </Button>
                        </div>
                    </>
            }

        </span>
    );
};

export default EditUsername;