import React from 'react';
import {Drawer} from "antd";

const Index = (props:{
    onClose: () => void,
    open: boolean,
    showDrawer: () => void,
    data: any
    coHostMember: string,
    host: Record<string, any>
}) => {
    return (
        <Drawer
            width={400}
            closable={false}
            onClose={props.onClose}
            open={props.open}
            className={"cohost-drawer-container"}

        >
            <div className={"flex justify-center items-center gap-5 cohost-drawer-wrapper"}>
                <div className={"coHost-image-wrapper cursor-pointer"} onClick={props.showDrawer}>
                    <img src={props.host?.profilePic} className={"coHost-image-dimensions"} alt={""} />
                </div>
                <div className={'flex-1'}>
                    <p className={"text-[1.4rem] font-medium"}>{props.host?.name}</p>
                    <p className={"text-base"}>Host</p>
                </div>
            </div>
            <hr />
            <div className={"cohost-drawer-wrapper"}>
                {
                    props.data?.length ?
                        props.data.map((item: any) => {
                            return (
                                <div key={item._id} className={"flex items-center gap-5 mb-5"}>
                                    <div className={"cohost-drawer-coHost-image-wrapper"}>
                                        <img src={item.profilePic} className={"cohost-drawer-coHost-image-dimensions"} alt={""} />
                                    </div>
                                    <div className={'flex-1 break-all'}>
                                        <p className={"capitalize font-500"}>{item.name}</p>
                                        <p className={"font-light"}>{item.email}</p>
                                        <p >{props.coHostMember === "coHost"? "coHost": "members"}</p>
                                    </div>
                                </div>
                            );
                        }) : null
                }
            </div>
        </Drawer>
    );
};

export default Index;
