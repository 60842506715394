import React from "react";
import "./index.scss";
import logo from "../../assets/LOGO-White.svg";
import {Link, NavLink} from "react-router-dom";
import {menuItems, userOption} from "./menuItems";
import {connect} from "react-redux";
import {logout} from "../../store/reducers/userSlice";
import {menuDataType, userOptionType} from "./types";

const Navbar = (props: { user: Record<string, any>, logout: () => void }) => {
    return (
        <header className={"header"}>
            <div className={"header-brand"}>
                <Link to={"/"}>
                    <img
                        src={logo}
                        alt={"Tabler"}
                        className={"brand-logo"}
                    />
                </Link>
            </div>
            <nav className={"header-menu"}>
                <div className={"menu"}>
                    {
                        menuItems.map((menu: menuDataType) => {
                            if (menu.onClick) {
                                return (
                                    <div key={menu.key} className={"cursor-pointer list-none"}>
                                        {menu.label}
                                    </div>
                                );
                            }
                            if (menu.link) {
                                return (
                                    <div key={menu.key} className={"menu-item list-none"}>
                                        <NavLink
                                            to={menu.link}
                                            className={({isActive}) => "item-link " + (isActive ? " active " : "")}
                                        >
                                            {menu.label}
                                        </NavLink>
                                    </div>
                                );
                            }
                            return <></>
                        })
                    }

                </div>
            </nav>
            <div className={"header-user"}>
                <div className={"user-avatar"}>
                    <img
                        src={props.user.profilePic ? props.user.profilePic : "https://picsum.photos/200"}
                        alt={props.user.name}
                        className={"avatar-img"}
                    />
                </div>
                <div className={"user-details"}>
                    <p className={"user-name"}>{props.user.name}</p>
                </div>
                <ul className={"user-options"}>
                    {
                        userOption.map((item: userOptionType) => (
                            <div key={item.key} className={"options"}>
                                <NavLink
                                    to={item.link}
                                    onClick={() => item.label === "logout" && props.logout()}
                                    className={"option-link"}
                                >
                                    {item.label}
                                </NavLink>
                            </div>
                        ))
                    }
                </ul>
            </div>
        </header>
    );
};
const mapStateToProps = (state: any) => ({
    user: state.user.user
})
export default connect(mapStateToProps, {logout})(Navbar);