import { combineReducers } from "redux";
import userSlice from "./userSlice";
import tablerSlice from "./tablerSlice";

const rootReducer = combineReducers({
  user: userSlice,
  tabler:tablerSlice
});

export default rootReducer;
