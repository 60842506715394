import React, { useState, useCallback } from 'react';
import { Button, Dropdown, Input, Modal, Typography, Select, Spin } from "antd";
import { fetchWithToken } from "../../../config/helper";
import { sendNotifications_Url, Tabler_url } from "../../../config/api";
import { notification } from "../../../common/Notification";

const { Option } = Select;

const SendBulkNotification = (props: { ids: React.Key[], filters: any }) => {
    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')
    const [type, setType] = useState('')
    const [loading, setLoading] = useState(false)
    const [tables, setTables] = useState([])
    const [selectedTableId, setSelectedTableId] = useState(null)
    const [fetchingTables, setFetchingTables] = useState(false)

    const sendNotification = () => {
        setLoading(true)
        fetchWithToken(sendNotifications_Url, "post", {}, JSON.stringify({
            ids: props.ids,
            option: type === '1' ? 'all' : type === '2' ? 'filtered' : 'selected',
            title,
            body,
            filters: props.filters,
            tableId: selectedTableId
        })).then((resp) => {
            if (resp.status === 200) {
                notification({
                    description: `Notification successfully sent ${menuProps.items[parseInt(type) - 1].label}`,
                    type: "success",
                    title: <p className={"capitalize"}>Notification sent</p>
                });
                setOpen(false)
                setBody('')
                setTitle('')
                setSelectedTableId(null)
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    })
                })
            }
        }).catch((e) => {
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const debounce = <T extends (...args: any[]) => void>(func: T, wait: number): ((...args: Parameters<T>) => void) => {
        let timeout: NodeJS.Timeout | null = null;
            
        return (...args: Parameters<T>) => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        }
    }
    
    const handleFetchTables = useCallback(debounce((inputVal: string) => fetchTables(inputVal), 500), []);

    // EventId is actually the string that I am searching for for filter
    const fetchTables = (value: string) => {
        setFetchingTables(true);
        setTables([]);
        fetchWithToken(`${Tabler_url}?eventId=${value}`, "get")
            .then((res) => res.json())
            .then((data) => {
                setTables(data.results || []);
            })
            .catch((error) => {
                console.error("Error fetching tables:", error);
            })
            .finally(() => {
                setFetchingTables(false);
            });
    };

    const menuProps = {
        items: [
            {key: '1', label: 'To All Users In DB'},
            {key: '2', label: 'To All Filtered Users'},
            {key: '3', label: 'To Selected Users On This Page'}
        ],
        onClick: (e: any) => {
            setOpen(true)
            setType(e.key)
        }
    }
    return (
        <>
            <Dropdown menu={menuProps} placement="bottomRight">
                <Button type="primary">Send Notification</Button>
            </Dropdown>
            <Modal
                title={<>Send Notification<p className={'text-xl'}>{type ? menuProps.items[parseInt(type) - 1].label : ''}</p></>}
                open={open}
                onCancel={() => {
                    setOpen(false)
                    setBody('')
                    setTitle('')
                    setSelectedTableId(null)
                }}
                onOk={() => sendNotification()}
                okButtonProps={{loading: loading, disabled: !title || !body}}
                cancelButtonProps={{disabled: loading}}
            >
                <div className={'m-10'}>
                    <Typography.Text strong>Notification Title</Typography.Text>
                    <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={'Title'}
                    />
                </div>
                <div className={'m-10'}>
                    <Typography.Text strong>Notification Body</Typography.Text>
                    <Input.TextArea
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        placeholder={'Body'}
                    />
                </div>
                <div className={'m-10'}>
                    <Typography.Text strong>Select Table</Typography.Text>
                    <Select
                        showSearch
                        value={selectedTableId}
                        placeholder="Search and select a table"
                        style={{ width: '100%' }}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        onSearch={handleFetchTables}
                        onChange={setSelectedTableId}
                        notFoundContent={fetchingTables ? <Spin size="small" /> : null}
                    >
                        {tables.map((table: any) => (
                            <Option key={table._id} value={table._id}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {table.eventId?.imageUrl && (
                                        <img 
                                            src={table.eventId.imageUrl} 
                                            alt="Event" 
                                            style={{ width: '30px', height: '30px', marginRight: '10px', objectFit: 'cover' }} 
                                        />
                                    )}
                                    {table.eventId?.eventName || table.eventName || 'Unnamed Table'}
                                </div>
                            </Option>
                        ))}
                    </Select>
                </div>
            </Modal>
        </>
    );
};

export default SendBulkNotification;