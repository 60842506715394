import React, {useEffect, useRef, useState} from "react";
import SectionHeader from "../../common/Section/SectionHeader";
import SectionBody from "../../common/Section/SectionBody";
import {user_Url} from "../../config/api";
import {Button, Dropdown, InputRef, Table} from "antd";
import {UserColumns} from "./UserColumns";
import "./index.scss";
import {fetchWithToken} from "../../config/helper";
import UserDrawer from "./components/userDrawer";
import getColumnSearchProps from "../../common/ColumnSearch";
import SendBulkNotification from "./components/SendBulkNotification";

const Index = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = React.useState<Record<string, any>[]>([]);
    const [total, setTotal] = React.useState<number>(0)
    const [params, setParams] = React.useState<any>({
        pageSize: 10,
        page: 1
    })
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const searchInput = useRef<InputRef>(null);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };

    const userFetchData = () => {
        setLoading(true)
        let query = []
        for (const key of Object.keys(params)) {
            if (key === "pageSize") {
                query.push(`results=${params.pageSize}`)
            } else {
                query.push(`${key}=${params[key]}`)
            }
        }
        const url = `${user_Url}?${query.join('&')}`;
        fetchWithToken(url, "get").then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    setData(data.results)
                    setTotal(data.total)
                }).finally(() => {
                    setLoading(false);
                })
            }
        })
    }

    useEffect(() => {
        userFetchData();
    }, [params]);

    const handleChange = (pagination: any, filters: any) => {
        const temp: any = {
            page: pagination.current,
            pageSize: pagination.pageSize
        }
        for (const key of Object.keys(filters)) {
            if (filters[key]) {
                temp[key] = filters[key]
            }
        }
        setParams(temp)
    }
    const columns = UserColumns.map((column: any) => ({
        ...column,
        ...(["name", "userName", "email", "phoneNumber", "bio"].includes(column.dataIndex) ? getColumnSearchProps(searchInput, column.dataIndex) : {}),
        ...(column.key === "actions" ? {
            render: (_: any, record: any) => <UserDrawer
                id={record._id}
                email={record.email}
                userName={record.userName}
                isDisabled={record.isDisabled}
                userFetchData={userFetchData}
            />
        } : {})
    }));
    return (
        <>
            <SectionHeader heading={"Users"}>
                <SendBulkNotification ids={selectedRowKeys} filters={params}/>
            </SectionHeader>
            <SectionBody className={"user-body"}>
                <Table
                    loading={loading}
                    pagination={{
                        current: params.page,
                        pageSize: params.pageSize,
                        total: total,
                        showTotal: (total) => <span className={"text-2xl font-600 capitalize"}>Total: {total}</span>,
                        showSizeChanger: true
                    }}
                    rowKey={(row)=>row._id}
                    dataSource={data}
                    columns={columns}
                    scroll={{y: "65vh"}}
                    rowSelection={rowSelection}
                    onChange={handleChange}
                />
            </SectionBody>
        </>
    );
};

export default Index;
