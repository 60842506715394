import React from 'react';
import {Statistic} from "antd";

const Stats = (props: { data: any }) => {
    return (
        <>
            <div className={"stats-wrapper"}>
                <Statistic title="New KYC" value={props.data.pendingKycs}/>
            </div>
            <div className={"stats-wrapper"}>
                <Statistic title="New Boost" value={props.data.pendingBoost}/>
            </div>
            <div className={"stats-wrapper"}>
                <Statistic title="New User Reports" value={props.data.openUserReports}/>
            </div>
            <div className={"stats-wrapper"}>
                <Statistic title="New Table Reports" value={props.data.openTableReports}/>
            </div>
        </>
    );
};

export default Stats;